<template>
  <div>
    <NavbarSinSlider />
    <PaginaEspecificaSkeleton v-if="onLoad" />
    <PaginaEspecifica v-else :data="this.itinerario" :contacto="true" />
  </div>
</template>

<script>
import Api from "../providers/api";
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import PaginaEspecifica from "../components/PaginaEspecifica.vue";
import PaginaEspecificaSkeleton from "../components/Skeletons/PaginaEspecificaSkeleton.vue";

export default {
  name: "Itinerario",
  components: {
    NavbarSinSlider,
    PaginaEspecifica,
    PaginaEspecificaSkeleton
  },
  data() {
    return {
      onLoad: true,
      itinerario: {
        id: this.$route.params.id,
        nombre: "",
        descripcion: "",
        detalles: "",
        direcciones: "",
        email: "",
        telefono: "",
        web: "",
        imagenes: [],
      },
    };
  },
  methods: {
    getItinerario(id) {
      const Itinerario = Api("itinerario");

      Itinerario.get(id).then((response) => {
        if (response.success) {
          this.itinerario = response.itinerario;
          this.onLoad = false;
        }
      });
    },
  },
  created: async function () {
    this.getItinerario(this.itinerario.id);
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 2;
  column-gap: 3em;
}

.shadow-container {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.2em !important;
  background-color: var(--gris-suave);
}
</style>
