<template>
  <div class="row web-container pl-5 pr-5" id="cuadricula-imagenes">
    <div class="col-md-6 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: `/actividades?city=${$route.params.title}` }">
        <div
          class="img-rectangular-grande"
          :style="`background-image: url('${base_url}images/cuadricula-ciudad/07b_584x240_actividades.jpg')`"
        ></div>
        <div id="texto-actividades">
          <span>Acti</span>
          <span>vida</span>
          <span>des</span>
        </div>
      </router-link>
    </div>
    <div class="col-md-3 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: `/alojamientos?city=${$route.params.title}` }">
        <div class="container-img-rectangular">
          <div
            class="img-rectangular"
            :style="`background-image: url('${base_url}images/cuadricula-ciudad/05b_288x240_alojamientos.jpg')`"
          ></div>
          <div class="texto-cuadrados-chicos">
            <span>alo</span>
            <span>ja</span>
            <span>mien</span>
            <span>tos</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-md-3 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: `/gastronomias?city=${$route.params.title}` }">
        <div class="container-img-rectangular">
          <div
            class="img-rectangular"
            :style="`background-image: url('${base_url}images/cuadricula-ciudad/10b_288x240_gastronomia.jpg')`"
          ></div>
          <div class="texto-cuadrados-chicos">
            <span>gas</span>
            <span>tro</span>
            <span>no</span>
            <span>mía</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-md-4 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: `/agencias-de-viaje?city=${$route.params.title}` }">
        <div class="container-img-rectangular">
          <div
            class="img-rectangular"
            :style="`background-image: url('${base_url}images/cuadricula-ciudad/agencias_de_viaje_390x240px.png')`"
          ></div>
          <div class="texto-cuadrados-chicos">
            <span>agen</span>
            <span>cias</span>
            <span class="mb-2 mt-2">de</span>
            <span>viaje</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-md-4 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: `/transporte?city=${$route.params.title}` }">
        <div class="container-img-rectangular">
          <div
            class="img-rectangular"
            :style="`background-image: url('${base_url}images/cuadricula-ciudad/transporte_390x240px.png')`"
          ></div>
          <div class="texto-cuadrados-chicos">
            <span>trans</span>
            <span>por</span>
            <span>te</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-md-4 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: `/otros-servicios?city=${$route.params.title}` }">
        <div class="container-img-rectangular">
          <div
            class="img-rectangular"
            :style="`background-image: url('${base_url}images/cuadricula-ciudad/otros_servicios_390x240px.png')`"
          ></div>
          <div class="texto-cuadrados-chicos">
            <span>otros</span>
            <span class="mt-2">servi</span>
            <span>cios</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-md-3 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: `/itinerarios?city=${$route.params.title}` }">
        <div class="container-img-rectangular">
          <div
            class="img-rectangular"
            :style="`background-image: url('${base_url}images/cuadricula-ciudad/09_380x200_itinerarios.jpg')`"
          ></div>
          <div class="texto-cuadrados-chicos">
            <span>iti</span>
            <span>ne</span>
            <span>ra</span>
            <span>rios</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-md-3 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: `/agenda?city=${$route.params.title}` }">
        <div class="container-img-rectangular">
          <div
            class="img-rectangular"
            :style="`background-image: url('${base_url}images/cuadricula-ciudad/06b_288x240_agenda.jpg')`"
          ></div>
          <div class="texto-cuadrados-chicos">
            <span>agen</span>
            <span>das</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-md-6 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: `/destinos?city=${$route.params.title}` }">
        <div class="container-img-rectangular">
          <div
            class="img-rectangular"
            :style="`background-image: url('${base_url}images/cuadricula-ciudad/08b_548x240_destinos.jpg')`"
          ></div>
          <div class="texto-cuadrados-chicos">
            <span class="mb-2">más</span>
            <span>Des</span>
            <span>ti</span>
            <span>nos</span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Configs from "../configs/configs";

export default {
  name: "CuadriculaImagenes",
  data() {
    return {
      base_url: Configs.BASE,
    };
  },
};
</script>

<style scoped>
#cuadricula-imagenes {
  margin-bottom: 1.5em;
}

.img-rectangular-grande {
  width: 100%;
  height: 15em;
  background-size: cover;
  filter: brightness(70%);
  transition: filter 0.2s ease-in-out;
}

.container-img-rectangular {
  width: 100%;
  height: 15em;
  background-size: cover;
}

.img-rectangular {
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: brightness(70%);
  transition: filter 0.2s ease-in-out;
}

.zoom-opacity:hover .img-rectangular-grande:hover,
.zoom-opacity:hover .container-img-rectangular:hover,
.zoom-opacity:hover .img-rectangular {
  filter: brightness(100%);
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

.col-md-6 {
  padding-left: 0;
  padding-right: 0;
}

.texto-cuadrados-chicos {
  top: 50%;
  transform: translateY(-50%);
  left: 90%;
  transform: translate(-100%, -50%);
  position: absolute;
  border-left: 0.3em solid var(--azul-2);
}

.texto-cuadrados-chicos span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2em;
  line-height: 0.9em;
  padding-left: 0.1em;
}

#texto-actividades {
  top: 50%;
  left: 95%;
  transform: translate(-100%, -50%);
  position: absolute;
  border-left: 0.3em solid var(--azul-2);
}

#texto-actividades span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2em;
  line-height: 0.9em;
  padding-left: 0.1em;
}
</style>
