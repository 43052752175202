<template>
  <div>
    <NavbarSinSlider />
    <PaginaEspecificaSkeleton v-if="onLoad" />
    <PaginaEspecifica v-else :data="noticia" :contacto="false" />
  </div>
</template>

<script>
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import PaginaEspecifica from "../components/PaginaEspecifica.vue";
import PaginaEspecificaSkeleton from "../components/Skeletons/PaginaEspecificaSkeleton.vue";
import Api from "../providers/api";

export default {
  name: "Noticia",
  components: {
    NavbarSinSlider,
    PaginaEspecifica,
    PaginaEspecificaSkeleton,
  },
  data() {
    return {
      onLoad: true,
      noticia: {},
      imagenes: [
        {
          id: "1",
          src:
            "https://gualeguaychutermal.com.ar/wp-content/uploads/fin-a%C3%B1o.jpg",
        },
        {
          id: "2",
          src: "https://www.turismoentrerios.com/colon/img/ini-playas.jpg",
        },
      ],
    };
  },
  methods: {
    getNoticia(id) {
      const Noticia = Api("noticia");
      Noticia.get(id).then((response) => {
        if (response) {
          response.noticia.descripcion = response.noticia.descripcion.replace(
            /(?:\\r\\n|\\n)/g,
            "<br>"
          );
          response.noticia.detalles = response.noticia.detalles.replace(
            /(?:\\r\\n|\\n)/g,
            "<br>"
          );
          this.noticia = response.noticia;
          this.onLoad = false;
        }
        
      });
    },
  },
  created: async function () {
    this.getNoticia(this.$route.params.id);
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 2;
  column-gap: 3em;
}
</style>