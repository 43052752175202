<template>
    <div class="social">
		<ul>
			<li><a href="https://www.instagram.com/turismoer/" target="_blank" class="icon-instagram"><i class="fa fa-2x fa-instagram" aria-hidden="true"></i></a></li>
			<li><a href="https://www.facebook.com/TurismoEntreRios/" target="_blank" class="icon-facebook"><i class="fa fa-2x fa-facebook-square" aria-hidden="true"></i></a></li>
			<li><a href="https://www.youtube.com/user/minturer" target="_blank" class="icon-youtube"><i class="fa fa-2x fa-youtube-square" aria-hidden="true"></i></a></li>
			<li><a href="mailto:infoturismoer@gmail.com" class="icon-mail"><i class="fa fa-2x fa-envelope-square" aria-hidden="true"></i></a></li>
		</ul>
	</div>
</template>

<script>

export default {
  name: 'SocialMedia',
};
</script>

<style scoped>
.social {
	position: fixed; /* Hacemos que la posición en pantalla sea fija para que siempre se muestre en pantalla*/
	right: 0; /* Establecemos la barra en la izquierda */
	top: 50%; /* Bajamos la barra 200px de arriba a abajo */
    transform: translate(0, -50%);
	z-index: 2000; /* Utilizamos la propiedad z-index para que no se superponga algún otro elemento como sliders, galerías, etc */
}
 
	.social ul {
		list-style: none;
	}
 
	.social ul li a {
		display: inline-block;
		color:#fff;
		background: #000;
		padding: 15px 15px;
		text-decoration: none;
		-webkit-transition:all 500ms ease;
		-o-transition:all 500ms ease;
		transition:all 500ms ease; /* Establecemos una transición a todas las propiedades */
	}
 
	.social ul li .icon-facebook {background:#3b5998;} /* Establecemos los colores de cada red social, aprovechando su class */
	.social ul li .icon-instagram {background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); }
	.social ul li .icon-youtube {background: #d95232;}
	.social ul li .icon-mail {background: #666666;}
 
	.social ul li a:hover {
		background: #000; /* Cambiamos el fondo cuando el usuario pase el mouse */
	}
</style>
