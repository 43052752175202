import { render, staticRenderFns } from "./NavbarSinSlider.vue?vue&type=template&id=fdf40ea8&scoped=true"
import script from "./NavbarSinSlider.vue?vue&type=script&lang=js"
export * from "./NavbarSinSlider.vue?vue&type=script&lang=js"
import style0 from "./NavbarSinSlider.vue?vue&type=style&index=0&id=fdf40ea8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdf40ea8",
  null
  
)

export default component.exports