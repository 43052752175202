<template>
  <div>
    <Portada v-if="this.info.imagenes" :imagenes="this.info.imagenes" :imagenes_m="this.info.imagenes_m" />
    <div class="container-width p-lg-5">
      <div class="row m-0 pl-5 pr-5">
        <div class="col-md-12 title-pagina">
          <span class="text-title-fullwidth">
            QUE HACER
            <!--<br />-->
            <!--<span class="pl-10">dades</span>-->
          </span>
        </div>
        <div class="col-md-12 mt-5 mb-3 container-intro">
          <span v-if="info.titulo" class="title-section">{{this.info.titulo}}</span>
          <div v-else class="bg-skeleton px-2 py-3 w-75 mx-auto"></div>
        </div>
        <div class="col-md-12 text-paragraph container-intro">
          <p v-if="info.descripcion">{{this.info.descripcion}}</p>
          <div v-else class="bg-skeleton px-2 py-5 w-75 mt-2 mx-auto"></div>
        </div>
        <div class="col-md-12 mt-5 mb-5">
          <CuadriculaActividades />
        </div>
      </div>
      <EventosCards />
    </div>
  </div>
</template>

<script>
import Portada from "../components/Portada.vue";
import CuadriculaActividades from "../components/CuadriculaActividades.vue";
import EventosCards from "../components/EventosCards.vue";
import PageInfo from "../tools/PageInfo";
import Api from "../providers/api";

export default {
  name: "Actividades",
  components: {
    Portada,
    CuadriculaActividades,
    EventosCards,
  },
  data() {
    return {
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
      listaLocalidades: []
    };
  },
  methods: {
    getLocalidades() {
      Api("localidad")
      .get()
      .then((res) => {
        this.listaLocalidades = res.localidad;
      });
    },
  },
  created: function () {
    this.getLocalidades();
    PageInfo.getPageInfo(this.$route.path, resp => {
      this.info = resp;

      if (this.$route.query.city) {
      let find = this.listaLocalidades.find(
        (x) =>
          x.nombre.replace(/\s/g, "-").toUpperCase() ==
          this.$route.query.city.toUpperCase()
      );
      if (find) {
        this.info.titulo = 'Conocé todas las actividades de ' + find.nombre; 
      }
    }
    });
  },
};
</script>

<style lang="css" scoped>
.bg-skeleton{
     background-color: #f5f5f5;
}
</style>