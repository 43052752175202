<template>
  <div>
    <NavbarSinSlider />
    <Modal
      id="modalError"
      title="Error al intentar enviar el contacto"
      message="Ha ocurrido un error al intentar enviar el contacto, por favor, intente nuevamente."
      icon="error"
    ></Modal>
    <Modal
      id="modalContactoEnviado"
      title="Contacto enviado correctamente"
      message="Se ha enviado el formulario de contacto correctamente."
      accept="handleRedirect"
      :displayConfirmBtn="true"
      :hideCloseBtn="true"
      icon="check"
    ></Modal>
    <section id="donde-ir">
      <div class="container-er">
        <div class="row m-0 pl-5 pr-5">
          <div class="col-md-12 title-pagina">
            <span class="text-title-fullwidth">
              con-
              <br />
              <span class="pl-10">tacto</span>
            </span>
          </div>
          <div class="col-md-12 mt-5 mb-5 text-paragraph container-intro">
            <p>{{ this.info.descripcion }}</p>
          </div>
          <form id="form">
            <div class="col-md-12">
              <div id="datos-personales" class="mb-5">
                <h1 class="text-azul w-900 lh-06 mb-0">Datos personales</h1>
                <div class="row mt-3">
                  <div class="col-xl-3 col-md-4 col-sm-6 mt-3">
                    <span class="text-capitalize title-section">nombre</span>
                    <input
                      type="text"
                      class="form-input-er form-control"
                      placeholder="Nombre"
                      v-model="form.nombre"
                      required
                    />
                  </div>
                  <div class="col-xl-3 col-md-4 col-sm-6 mt-3">
                    <span class="text-capitalize title-section">email</span>
                    <input
                      type="email"
                      class="form-input-er form-control"
                      placeholder="Email"
                      v-model="form.email"
                      required
                    />
                  </div>
                  <div class="col-xl-3 col-md-4 col-sm-6 mt-3">
                    <span class="text-capitalize title-section">provincia</span>
                    <select
                      class="form-input-er form-control"
                      v-model="form.provincia"
                      @change="setProvincia"
                      required
                    >
                      <option value="-1">Seleccione una provincia</option>
                      <option
                        v-for="provincia in getProvincias"
                        :value="provincia"
                        :key="'prov-' + provincia"
                      >
                        {{ provincia }}
                      </option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-md-4 col-sm-6 mt-3">
                    <span class="text-capitalize title-section"
                      >departamento</span
                    >
                    <select
                      class="form-input-er form-control"
                      v-model="form.departamento"
                      @change="setDepartamento"
                      required
                    >
                      <option value="-1">Seleccione un departamento</option>
                      <option
                        v-for="departamento in getDepartamento"
                        :value="departamento"
                        :key="'dep-' + departamento"
                      >
                        {{ departamento }}
                      </option>
                    </select>
                  </div>
                  <div class="col-xl-3 col-md-4 col-sm-6 mt-3">
                    <span class="text-capitalize title-section">localidad</span>
                    <select
                      class="form-input-er form-control"
                      v-model="form.localidad"
                      required
                    >
                      <option value="-1">Seleccione una localidad</option>
                      <option
                        v-for="localidad in getLocalidad"
                        :value="localidad"
                        :key="'loc-' + localidad"
                      >
                        {{ localidad }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <hr />
              <div id="motivos-causa" class="mt-5 mb-5">
                <h1 class="text-azul w-900 lh-06 mb-3">Motivos / causa</h1>
                <div class="row mt-3">
                  <div class="col-md-6 col-sm-12 mt-3">
                    <span class="text-capitalize title-section">asunto</span>
                    <select
                      class="form-input-er form-control"
                      v-model="form.asunto"
                      required
                    >
                      <option value>Seleccione el motivo de su consulta</option>
                      <option value="Consulta por promoción">
                        Consulta por promoción
                      </option>
                      <option value="Envío de evento de agenda">
                        Envío de evento de agenda
                      </option>
                      <option value="Envío de nota de novedad">
                        Envío de nota de novedad
                      </option>
                      <option
                        value="Soy prestador y deseo enviar una promoción"
                      >
                        Soy prestador y deseo enviar una promoción
                      </option>
                      <option
                        value="Soy prestador y deseo actualizar mis datos"
                      >
                        Soy prestador y deseo actualizar mis datos
                      </option>
                      <option value="Consulta enviada desde entrerios.tur.ar">
                        Otra consulta
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-12 mt-3">
                    <span class="text-capitalize title-section">mensaje</span>
                    <textarea
                      class="form-input-er form-control"
                      rows="10"
                      placeholder="Mensaje..."
                      v-model="form.mensaje"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="mt-5 mb-5 text-center">
                <span class="btn btn-primary" v-if="isLoading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
                <span v-else class="btn btn-primary" @click="sendForm()">
                  Enviar formulario
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import Modal from "../components/Modal.vue";
import PageInfo from "../tools/PageInfo";
import ProvinciasJSON from "../assets/info/provincias.json";
import Api from "../providers/api";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6Lew08cZAAAAAMxrhvi4Jo4lF4HrhGfEyTR_wNDN" });

export default {
  name: "DondeIr",
  components: {
    NavbarSinSlider,
    Modal,
  },
  data() {
    return {
      isLoading: false,
      inputs: {
        provincia: "",
        departamento: "",
        localidad: "",
      },
      form: {
        nombre: "",
        email: "",
        pais: "",
        provincia: "-1",
        departamento: "-1",
        localidad: "-1",
        asunto: "",
        mensaje: "",
        recaptcha_response: "",
      },
      provincias: [],
      departamentos: [],
      ciudades: [],
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
    };
  },
  computed: {
    getProvincias: function () {
      return Object.keys(ProvinciasJSON.Argentina[0]);
    },

    getDepartamento: function () {
      if (this.inputs.provincia) {
        return Object.keys(
          ProvinciasJSON.Argentina[0][this.inputs.provincia].ciudad
        );
      } else {
        return [];
      }
    },
    getLocalidad: function () {
      if (this.inputs.provincia && this.inputs.departamento) {
        return Object.keys(
          ProvinciasJSON.Argentina[0][this.inputs.provincia].ciudad[
            this.inputs.departamento
          ].localidad
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    formValidate() {
      let form = document.querySelector("#form");

      let validity = form.checkValidity();
      if (validity) {
        return true;
      } else {
        form.reportValidity();
      }
    },
    setProvincia() {
      this.inputs = {
        provincia: this.form.provincia,
        departamento: "",
        localidad: "",
      };
      this.form.departamento = -1;
      this.form.localidad = -1;
    },
    setDepartamento() {
      this.inputs = {
        provincia: this.form.provincia,
        departamento: this.form.departamento,
        localidad: "",
      };
      this.form.localidad = -1;
    },
    sendForm() {
      if (this.formValidate()) {
        this.isLoading = true;
        this.$recaptcha("contacto").then((token) => {
          // this.parseContactInfo(this.form);
          // parse & store data. Method can be housed in Vuex store
          // show confirmation message

          this.form.recaptcha_response = token;

          const Contacto = Api("contacto");

          Contacto.post(this.form).then((res) => {
            if (res.success) {
              this.showContactoEnviado();
            } else {
              this.showModalError();
              this.isLoading = false;
            }
          });

          // navigate to home page after processing  data
        });

        // console.log('formulario: ', this.form)
      }
    },
    showModalError() {
      document.querySelector("#modalError").classList.add("fade");
      document.querySelector("#modalError").classList.add("show");
      document.querySelector("#modalError").style.display = "block";
    },
    showContactoEnviado() {
      document.querySelector("#modalContactoEnviado").classList.add("fade");
      document.querySelector("#modalContactoEnviado").classList.add("show");
      document.querySelector("#modalContactoEnviado").style.display = "block";
    },
    handleRedirect() {
      this.$router.push("/");
    },
  },
  created: function () {
    PageInfo.getPageInfo(this.$route.path, (resp) => {
      this.info = resp;
    });
  },
};
</script>

<style scoped>
.title-section {
  font-size: 1.5em;
}

@media (max-width: 414px) {
  .title-pagina {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}
</style>
