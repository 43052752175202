<template>
  <div>
    <Portada v-if="this.info.imagenes" :imagenes="this.info.imagenes" :imagenes_m="this.info.imagenes_m" />
    <div class="container-width">
      <div class="row m-0">
        <div class="col-md-12 title-pagina">
          <span class="text-title-fullwidth">
            desti-
            <br />
            <span class="pl-10">nos</span>
          </span>
        </div>
        <div class="col-md-12 mt-5 mb-3 container-intro">
          <span v-if="info.titulo" class="title-section">{{this.info.titulo}}</span>
          <div v-else class="bg-skeleton px-2 py-3 w-75 mx-auto"></div>
        </div>
        <div class="col-md-12 text-paragraph container-intro">
          <p v-if="info.descripcion">{{this.info.descripcion}}</p>
          
          <div v-else class="bg-skeleton px-2 py-5 w-75 mt-2 mx-auto"></div>
        </div>
        <div v-if="destinos.length" class="col-md-12 pt-5 mb-5">
          <CuadriculaDestinos :destinos="destinos" />
        </div>
        <div v-else class="col-md-12 pt-5 mb-5">
          <CuadriculaDestinosSkeleton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Portada from "../components/Portada.vue";
import CuadriculaDestinos from "../components/CuadriculaDestinos.vue";
import Configs from "../configs/configs.js";
import CuadriculaDestinosSkeleton from "../components/Skeletons/CuadriculaDestinosSkeleton.vue"
import Api from "../providers/api";
import PageInfo from "../tools/PageInfo";

export default {
  name: "Destinos",
  components: {
    Portada,
    CuadriculaDestinos,
    CuadriculaDestinosSkeleton
  },
  data() {
    return {
      destinos: [],
      servicios: [],
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
    };
  },
  methods: {
    getDestinos() {
        const Destino = Api("destino")

      Destino.get().then( response => {
        this.destinos = response.success ? response.destino : [];
      })
    },
    
  },
  created: async function () {
    PageInfo.getPageInfo(this.$route.path, resp => {
      this.info = resp
    });

    this.getDestinos();
  },
};
</script>

<style scoped>
#spinner {
  color: var(--azul);
}

.container-intro {
  text-align: center;
  padding-left: 8em;
  padding-right: 8em;
}

.bg-skeleton{
     background-color: #f5f5f5;
}
</style>
