<template>
  <div>
    <NavbarSinSlider />
    <PaginaEspecificaSkeleton v-if="onLoad" />
    <PaginaEspecifica v-else :data="this.evento" :contacto="false" />
  </div>
</template>

<script>
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import PaginaEspecifica from "../components/PaginaEspecifica.vue";
import PaginaEspecificaSkeleton from "../components/Skeletons/PaginaEspecificaSkeleton.vue"
import Api from "../providers/api";

export default {
  name: "EventoAgenda",
  components: {
    NavbarSinSlider,
    PaginaEspecifica,
    PaginaEspecificaSkeleton
  },
  data() {
    return {
      onLoad: true,
      evento: {
        id: this.$route.params.id,
        nombre: "",
        descripcion: "",
        detalles: "",
        imagenes: [],
      },
    };
  },
  methods: {
    getEvento(id) {
      const Agenda = Api("agenda");

      Agenda.get(id).then((response) => {
        if (response.success){
          this.evento = response.agenda
          this.onLoad = false;
        }
      });
    },
  },
  created: async function () {
    this.getEvento(this.evento.id);
    this.evento.descripcion = this.evento.descripcion.replace(
      /(?:\\r\\n|\\n)/g,
      "<br>"
    );
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 2;
  column-gap: 3em;
}
</style>
