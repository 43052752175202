<template>
  <div class="row">
    <div class="col-md-6 img-container mb-2" v-for="i in 8" :key="'skitem'+i">
      <div class="img-cuadrada px-3 d-flex align-items-end pb-2 item-load" >
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CuadriculaItinerariosSkeleton",
  props: {
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.img-cuadrada {
  width: 100%;
  height: 18em;
  background-size: auto;
  background-color: #F5F5F5;
  background-position: center;
}

.bg-skeleton{
    background-color: #ffffff;
}
.text-skeleton{
    height: 1.8em;
    margin-left: 0;
}

.item-load{
  position: relative;
    overflow: hidden;
}
.item-load::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #F9F9F9 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}
</style>