<template>
  <div>
    <NavbarSinSlider />
    <PaginaEspecifica :data="agencia" :contacto="true" />
  </div>
</template>

<script>

import Api from "../providers/api";
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import PaginaEspecifica from "../components/PaginaEspecifica.vue";

export default {
  name: "AgenciaDeViaje",
  components: {
    NavbarSinSlider,
    PaginaEspecifica,
  },
  data() {
    return {
      agencia: {
        id: this.$route.params.id,
        nombre: "",
        descripcion: "",
        detalles: "",
        direcciones: "",
        email: "",
        telefono: "",
        web: "",
        imagenes: [],
      }
    };
  },
  methods: {
    getAgencia(id) {
      const Servicio = Api("servicio");

      Servicio.get(id).then((response) => {
        response.servicio.descripcion = response.servicio.descripcion.replace(
      /(?:\\r\\n|\\n)/g,
      "<br>"
    );
        
        this.agencia = response.servicio;

      });
    },
  },
  created: async function () {
    this.getAgencia(this.agencia.id);
    this.agencia.descripcion = this.agencia.descripcion.replace(
      /(?:\\r\\n|\\n)/g,
      "<br>"
    );
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 2;
  column-gap: 3em;
}

.shadow-container {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.2em !important;
  background-color: var(--gris-suave);
}
</style>
