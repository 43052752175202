<template>
  <div>
    <Portada v-if="this.info.imagenes" :imagenes="this.info.imagenes" :imagenes_m="this.info.imagenes_m" />
    <div class="container-width">
      <div class="row m-0 pl-5 pr-5">
        <div class="col-md-12 title-pagina">
          <span class="text-title-fullwidth">{{tituloPagina}}</span>
        </div>
        <div class="col-md-12 mt-5 mb-5 container-intro text-center">
          <span v-if="info.titulo" class="title-section">{{this.info.titulo}}</span>
          <div v-else class="bg-skeleton px-2 py-3 w-75 mx-auto"></div>
        </div>
      </div>

      <div class="row mx-0" v-if="agenda.onLoad">
        <div class="col-md-6 col-lg-4 col-xl-3" v-for="i in 8" v-bind:key="'skitem-'+i">
          <ItemDescriptivoSkeleton />
        </div>
      </div>

      <div class="web-container p-5">
        <div v-if="this.agenda.onLoad == false && this.agenda.lista.length > 0">
          <div class="row mx-0">
            <div
              class="col-md-6 col-lg-4 col-xl-3"
              v-for="agenda in this.agenda.lista"
              v-bind:key="agenda.id"
            >
              <ItemDescriptivo
                :title="agenda.nombre"
                :description="agenda.descripcion"
                :img="agenda.src"
                :link="'agenda/' + agenda.id"
              />
            </div>
          </div>
        </div>
        <div
          v-if="this.agenda.onLoad == false && this.agenda.lista.length == 0"
          class="text-center"
        >
          <h4>No hay eventos registrados.</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Portada from "../components/Portada.vue";
import ItemDescriptivo from "../components/ItemDescriptivo.vue";
import ItemDescriptivoSkeleton from "../components/Skeletons/ItemDescriptivoSkeleton.vue";
import Api from "../providers/api";
import PageInfo from "../tools/PageInfo";

export default {
  name: "Agenda",
  components: {
    Portada,
    ItemDescriptivo,
    ItemDescriptivoSkeleton,
  },
  data() {
    return {
      tituloPagina: "Agenda",
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
      agenda: {
        onLoad: false,
        lista: [],
      },
    };
  },
  methods: {
    getEventos() {
      this.agenda.onLoad = true;

      const Agenda = Api("agenda");

      Agenda.get().then((response) => {
        this.agenda.lista = response.success ? response.agenda : [];
        this.agenda.onLoad = false;
      });
    },
  },
  created: function () {
    PageInfo.getPageInfo(this.$route.path, (resp) => {
      this.info = resp;
    });
    this.getEventos();
  },
};
</script>

<style scoped>
#loading-spinner {
  width: 100%;
  height: 10em;
}

.bg-skeleton{
     background-color: #f5f5f5;
}
</style>
