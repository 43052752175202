<template>
  <div>
      <iframe :src="url" width="100%" height="600"></iframe>
  </div>
</template>

<script>
export default {
  name: "MapGoogle",
  props: {
    url: String
  }
};
</script>

<style scoped>
</style>