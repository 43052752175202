<template>
  <div>
    <Portada v-if="this.info.imagenes" :imagenes="this.info.imagenes" :imagenes_m="this.info.imagenes_m" />
    <div class="container-width">
      <div class="row m-0 px-lg-5 px-1 pb-5">
        <div class="col-md-12 title-pagina">
          <span class="text-title-fullwidth">
            itine-
            <br />
            <span class="pl-10">rarios</span>
          </span>
        </div>
        <div class="col-md-12 mt-5 mb-3 container-intro">
          <p v-if="info.descripcion">{{ this.info.descripcion }}</p>

          <div v-else class="bg-skeleton px-2 py-5 w-75 mt-2 mx-auto"></div>
        </div>
        <div class="col-md-12 text-paragraph container-intro">
          <p>{{ this.info.descripcion }}</p>
        </div>
        <div class="col" v-if="!onLoad">
          <div class="row mx-auto">
            <div
              class="col-md-6 pt-5"
              v-for="itinerario in this.itinerarios"
              :key="itinerario.id"
            >
              <div
                class="img-cuadrada"
                :style="`background-image: url('${url + itinerario.src}')`"
              ></div>
              <div class="texto-donde-ir">
                <span class="img-title">{{ itinerario.nombre }}</span>
                <span class="border-text"></span>
                <span class="text-link text-center">
                  <router-link
                    :to="{
                      path: `/itinerario/${itinerario.id}/${parserTitle(
                        itinerario.nombre
                      )}`,
                    }"
                    >Ver detalles</router-link
                  >
                </span>
              </div>
            </div>
          </div>
          <div v-if="!itinerarios.length">
            <h4 class="text-center py-4">
              No se encontraron itinerarios para este destino
            </h4>
          </div>
        </div>
        <CuadriculaItinerariosSkeleton class="col-12 mx-auto" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Portada from "../components/Portada.vue";
import Configs from "../configs/configs.js";
import Api from "../providers/api";
import CuadriculaItinerariosSkeleton from "../components/Skeletons/CuadriculaItinerariosSkeleton.vue";
import PageInfo from "../tools/PageInfo";
import Destinos from "../assets/info/destinos.json";

export default {
  name: "Itinerarios",
  components: {
    Portada,
    CuadriculaItinerariosSkeleton,
  },
  data() {
    return {
      onLoad: true,
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
      itinerarios: [],
      url: Configs.API.IMG,
    };
  },
  methods: {
    parserTitle(txt) {
      return txt.replace(/(?:\s|\.)/g, "-");
    },
    getItinerarios() {
      const Itinerario = Api("itinerario");

      Itinerario.get().then(async (response) => {
        this.itinerarios = response.itinerario;
        if (this.$route.query.city) {
          let destino = await Destinos.find(
            (x) => x.nombre.replace(/\s/g, "-") == this.$route.query.city
          );
          let itinerarios = response.itinerario.filter(
            (x) => x.id_localidad == destino.id
          );

          this.itinerarios = response.success ? itinerarios : [];
        }
        this.onLoad = false;
      });
    },
  },
  created: async function () {
    PageInfo.getPageInfo(this.$route.path, (resp) => {
      this.info = resp;
    });

    this.getItinerarios();
  },
};
</script>

<style scoped>
.container-intro {
  text-align: center;
  padding-left: 8em;
  padding-right: 8em;
}

.img-cuadrada {
  width: 100%;
  height: 22em;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-title {
  text-align: center !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.7em;
  padding-left: 1em;
  padding-right: 1em;
}

.texto-donde-ir {
  bottom: 0%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;

  /* nuevo */
  height: 7em;
  width: 95%;
  background: black;
  opacity: 0.7;
}

.texto-donde-ir span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6em;
  line-height: 0.5em;

  /* nuevo */
  padding-top: 1em;
}

.text-link {
  padding-top: 0.2em !important;
}

.text-link a {
  color: white;
  /* text-decoration: underline; */
  font-size: 0.6em;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.texto-donde-ir .border-text {
  border-bottom: 0.15em solid white;
  width: 3em;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1024px) {
  .texto-donde-ir {
    width: 91% !important;
  }
}

@media (max-width: 413px) {
  .texto-donde-ir {
    width: 90% !important;
  }
}

@media (max-width: 374px) {
  .texto-donde-ir {
    width: 86% !important;
  }
}

.bg-skeleton {
  background-color: #f5f5f5;
}
</style>
