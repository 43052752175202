import { render, staticRenderFns } from "./ItemDescriptivoSkeleton.vue?vue&type=template&id=36b8d784&scoped=true"
import script from "./ItemDescriptivoSkeleton.vue?vue&type=script&lang=js"
export * from "./ItemDescriptivoSkeleton.vue?vue&type=script&lang=js"
import style0 from "./ItemDescriptivoSkeleton.vue?vue&type=style&index=0&id=36b8d784&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b8d784",
  null
  
)

export default component.exports