<template>
  <div>
    <Portada v-if="this.info.imagenes" :imagenes="this.info.imagenes" :imagenes_m="this.info.imagenes_m" />
    <div class="container-width">
      <div class="row m-0 pl-5 pr-5">
        <div class="col-md-12 title-pagina">
          <span class="text-title-fullwidth">{{tituloPagina}}</span>
        </div>
        <div class="col-md-12 mt-5 mb-5 container-intro">
          <span class="title-section">{{this.info.titulo}}</span>
        </div>
        <div class="col-md-12 text-paragraph container-intro">
          <p>{{this.info.descripcion}}</p>
        </div>
        
      </div>
      <Filtro
        id="filter"
        :localidades="true"
        :categoria="true"
        :subCategorias="true"
        :proximidad="true"
        type="actividad"
        actividad="turismo-en-naturaleza"
      />

      <div class="web-container p-5">
        <div v-if="!this.actividades.onLoad && listActivities.length">
          <div class="row">
            <div class="col-md-6 col-lg-4 col-xl-3" v-for="actividad in listActivities" v-bind:key="actividad.id">
              <ItemDescriptivo
                :title="actividad.nombre"
                :description="actividad.descripcion"
                :img="actividad.src"
                :link="'/actividad/' + actividad.id"
              />
            </div>
          </div>
          <div class="pagination text-right justify-content-center">
            <ul class="pagination">
              <li class="page-item">
                <span
                  class="page-link np"
                  @click="handleChangePageNP(-1)"
                  :class="pagination.current == 1 ? 'd-none' : ''"
                >Anterior</span>
              </li>

              <li
                class="page-item"
                v-for="page in itemPaginacion"
                :key="'pag'+page"
                :class="[page == pagination.current ? 'actived' : '']"
              >
                <a class="page-link cursor-pointer" @click="handleChangePage(page)">{{page}}</a>
              </li>

              <li class="page-item" v-if="pagination.totalForPagination > 1">
                <span
                  class="page-link np cursor-pointer"
                  @click="handleChangePageNP(1)"
                  :class="pagination.current == pagination.totalForPagination ? 'd-none' : ''"
                >Siguiente</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12" v-if="this.actividades.onLoad">
          <div class="row">
            <div class="col-md-3" v-for="i in 8" v-bind:key="'skitem-'+i">
              <ItemDescriptivoSkeleton />
            </div>
          </div>
        </div>
        <div v-if="!this.actividades.onLoad && !listActivities.length" class="text-center">
          <h4>No hay actividades registradas con los filtros seleccionados.</h4>
        </div>
      </div>
      <div class="col-md-12 mt-5 text-center">
          <span class="title-section">Vista 360º</span>
          <Video360 imageName="naturaleza.jpg" />
      </div>
      <br>
      <div class="pl-lg-5 pr-lg-5 google-map">
          <div class="mx-auto">
            <iframe 
              src="https://www.google.com/maps/d/embed?mid=1NGdrS2pxJeY8v4-AOPQmPtvRJHaqEZ8&ehbc=2E312F&noprof=1"
              width="1280"
              height="960">
            </iframe>
<!--            <iframe
              src="https://www.google.com/maps/d/embed?mid=1bEeUsBtEGHdV2ff4gJbfX01pZxYi0l-x"
              width="100%"
              height="480"
            ></iframe>-->
          </div>
        </div>
        
<!--      <div class="mb-3 p-5">
        <MapGoogle :url="map.url" v-if="map.display" />
      </div>-->
    </div>
  </div>
</template>

<script>
import Portada from "../components/Portada.vue";
import Filtro from "../components/Filtro.vue";
import ItemDescriptivo from "../components/ItemDescriptivo.vue";
import MapGoogle from "../components/MapGoogle.vue";

import ActividadesJSON from "../assets/info/actividades.json";
import CercaniasJSON from "../assets/info/cercanias.json";
import ItemDescriptivoSkeleton from "../components/Skeletons/ItemDescriptivoSkeleton.vue";
import Video360 from "../components/Video360.vue";

import Api from "../providers/api";
import PageInfo from "../tools/PageInfo";

export default {
  name: "TurismoEnNaturaleza",
  components: {
    Portada,
    ItemDescriptivo,
    Filtro,
    MapGoogle,
    ItemDescriptivoSkeleton,
    Video360,
  },
  data() {
    return {
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
      pagination: {
        total: -1,
        current: 1,
        totalForPagination: -1,
        numberPerPage: 12,
      },
      filter: {
        localidad: -1,
        categoria: -1,
        subCategoria: -1,
        cercanias: false,
        name: ""
      },
      tituloPagina: "",
      categoriasFiltradas: [],
      subtiposFiltrados: [],
      map: {
        display: false,
        url: "",
      },
      actividades: {
        onLoad: true,
        lista: [],
      },
      listaLocalidades: [],
      localidades: [],
    };
  },
  computed: {
    itemPaginacion: function () {
      let min =
        this.pagination.current - 5 > 0 ? this.pagination.current - 5 : 1;
      let max =
        this.pagination.current + 5 > this.pagination.totalForPagination
          ? this.pagination.totalForPagination
          : this.pagination.current + 5;

      let _ = [];

      for (let i = min; i <= max; i++) {
        _.push(i);
      }

      return _;
    },
    listActivities: function () {
      const newList = this.actividades.lista
        .filter(
          (x) =>
            (this.filter.localidad == -1 ||
              (this.filter.cercanias
                ? this.isNear(x.id_localidad)
                : x.id_localidad == this.filter.localidad)) &&
            (this.filter.categoria == -1 ||
              (this.filter.subCategoria == -1
                ? this.subtiposFiltrados.find(
                    (xs) =>
                      xs.idCategoriaPadre == this.filter.categoria &&
                      xs.id == x.id_subtipo_actividad
                  )
                : x.id_subtipo_actividad == this.filter.subCategoria)) && (x.nombre
            .toLowerCase()
            .includes(this.filter.name.toLowerCase()))
        )
        .slice(
          (this.pagination.current - 1) * this.pagination.numberPerPage,
          this.pagination.current * this.pagination.numberPerPage
        );
      return newList;
    },
  },
  methods: {
    handleChangePageNP(direction) {
      this.pagination.current +=
        direction == -1 && this.pagination.current ? -1 : 0;
      this.pagination.current +=
        direction == 1 &&
        this.pagination.current != this.pagination.totalForPagination
          ? 1
          : 0;
    },
    isNear(id) {
      if (id == this.filter.localidad) return true;
      else {
        let lista = CercaniasJSON.find(
          (cercania) => cercania.id == this.filter.localidad
        );
        return lista ? lista.cercanias.includes(Number(id)) : false;
      }
    },
    reloadPagination() {
      const newList = this.actividades.lista.filter(
        (x) =>
          (this.filter.localidad == -1 ||
            (this.filter.cercanias
              ? this.isNear(x.id_localidad)
              : x.id_localidad == this.filter.localidadd)) &&
          (this.filter.categoria == -1 ||
            (this.filter.subCategoria == -1
              ? this.subtiposFiltrados.find(
                  (xs) =>
                    xs.idCategoriaPadre == this.filter.categoria &&
                    xs.id == x.id_subtipo_actividad
                )
              : x.id_subtipo_actividad == this.filter.subCategoria)) && (x.nombre
            .toLowerCase()
            .includes(this.filter.name.toLowerCase()))
      );
      this.pagination.totalForPagination =
        parseInt(newList.length / this.pagination.numberPerPage) +
        (newList.length % this.pagination.numberPerPage ? 1 : 0);
      this.pagination.total =
        parseInt(newList.length / this.pagination.numberPerPage) +
        (newList.length % this.pagination.numberPerPage ? 1 : 0);
    },
    
    handleChangePage(page) {
      this.pagination.current = page;
    },
    handleChangeFilterName(name){
      this.filter.name = name;
      this.reloadPagination();
    },

    handleChangeFilterCercanias(bool) {
      this.filter.cercanias = bool;
      this.reloadPagination();
    },
    handleChangeFilterLocalidad(id) {
      this.filter.localidad = id;
      this.reloadPagination();
    },
    handleChangeFilterSubCategoria(id) {
      this.filter.subCategoria = id;
      this.reloadPagination();
    },
    handleChangeFilterCategoria(id) {
      this.filter.categoria = id;
      this.reloadPagination();
    },
    getLocalidades() {
      Api("localidad")
      .get()
      .then((res) => {
        this.listaLocalidades = res.localidad;
      });
    },
    getActivityInfo() {
      let activityUrl = "turismo-en-naturaleza";

      this.categoriasFiltradas = [];
      this.subtiposFiltrados = [];

      ActividadesJSON.categorias.forEach((a) => {
        if (a.url == activityUrl) {
          if (a.url != "turismo-en-naturaleza") {
            this.tituloPagina = a.nombre;
          } else {
            this.tituloPagina = "Turismo en naturaleza";
          }

          this.categoriasFiltradas.push({
            id: a.id,
            nombre: a.nombre,
          });

          if (a.subcategorias != null) {
            a.subcategorias.forEach((s) => {
              this.subtiposFiltrados.push({
                idCategoriaPadre: a.id,
                id: s.id,
                nombre: s.nombre,
              });
            });
          }
        }
      });
    },
  },
  beforeMount() {
    this.getActivityInfo();
  },
  created: function () {
    PageInfo.getPageInfo(this.$route.path, resp => {
      this.info = resp
    });
    if (this.$route.query.city) {
      let find = this.listaLocalidades.find(
        (x) =>
          x.nombre.replace(/\s/g, "-").toUpperCase() ==
          "turismo-en-naturaleza".toUpperCase()
      );
      if (find) {
        this.filter.localidad = find.id;
      }
    }

    let actividades = Api("actividad?tipo=2");
    actividades.get().then((x) => {
      this.actividades.lista = x.actividad;

      let _ = Object(x.actividad);
      _ = _.filter(
        (x) =>
          (Number(this.filter.localidad) == -1 ||
            (this.filter.cercanias
              ? this.isNear(x.id_localidad)
              : Number(x.id_localidad) == Number(this.filter.localidad))) &&
          (this.filter.categoria == -1 ||
            x.id_subtipo_servicio == this.filter.categoria)
      );
      this.pagination.totalForPagination =
        parseInt(_.length / this.pagination.numberPerPage) +
        (_.length % this.pagination.numberPerPage ? 1 : 0);
      this.pagination.total =
        parseInt(_.length / this.pagination.numberPerPage) +
        (_.length % this.pagination.numberPerPage ? 1 : 0);
      this.actividades.onLoad = false;
    });

    let map = ActividadesJSON.categorias.find(
      (e) => e.url == "turismo-en-naturaleza"
    ).map_url;
    if (map) {
      this.map = {
        display: true,
        url: map,
      };
    }
  },
};
</script>

<style scoped>
#loading-spinner {
  width: 100%;
  height: 10em;
}

li.page-item.actived > a {
  background-color: var(--azul-3) !important;
  color: #fff;
}
.page-link.np {
  color: var(--azul-3) !important;
}
</style>
