<template>
  <div>
    <NavbarSinSlider />
    <PaginaEspecificaSkeleton v-if="onLoad" />
    <PaginaEspecifica v-else :data="actividad" :contacto="true" />
  </div>
</template>

<script>
import Api from "../providers/api";
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import PaginaEspecifica from "../components/PaginaEspecifica.vue";
import PaginaEspecificaSkeleton from "../components/Skeletons/PaginaEspecificaSkeleton.vue";

export default {
  name: "ActividadEspecifica",
  components: {
    NavbarSinSlider,
    PaginaEspecifica,
    PaginaEspecificaSkeleton,
  },
  data() {
    return {
      onLoad: true,
      actividad: {
        id: this.$route.params.id,
        nombre: "",
        descripcion: "",
        detalles: "",
        direcciones: "",
        email: "",
        telefono: "",
        web: "",
        facebook: "",
        instagram: "",
        imagenes: [],
      },
      imagenes: [
        {
          id: "1",
          src:
            "https://gualeguaychutermal.com.ar/wp-content/uploads/fin-a%C3%B1o.jpg",
        },
        {
          id: "2",
          src: "https://www.turismoentrerios.com/colon/img/ini-playas.jpg",
        },
      ],
    };
  },
  methods: {
    getActividad(id) {
      const Actividad = Api("actividad");
      Actividad.get(id).then((response) => {
        if (response.success) this.actividad = response.actividad;
        this.onLoad = false;
      });
    },
  },
  created: function () {
      this.getActividad(this.actividad.id);
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 2;
  column-gap: 3em;
}

.shadow-container {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.2em !important;
  background-color: var(--gris-suave);
}
</style>
