<template>
  <div class="d-flex justify-content-center">
      <div style="height: 500px; width: 900px">
    <Pano :source="base_url + 'images/360/' + imageName"></Pano>
  </div>
  </div>
</template>

<script>
import Configs from '../configs/configs'
import { Pano } from "vuejs-vr";

export default {
  name: "Video360",
  data() {
    return {
      base_url: Configs.BASE
    };
  },
  components: {
    Pano,
  },
  methods: {
  },
  props: {
    imageName: String,
  },
};
</script>

<style scoped>
</style>