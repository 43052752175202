<template>
  <div>
    <Portada v-if="this.info.imagenes" :imagenes="this.info.imagenes" :imagenes_m="this.info.imagenes_m" />
    <div class="container-width">
      <div class="row m-0 pl-5 pr-5 pb-5">
        <div class="col-md-12 title-pagina">
          <span class="text-title-fullwidth">
            Promo-
            <br />
            <span class="pl-10">ciones</span>
          </span>
        </div>

        <div class="col-md-12 mt-5 mb-3 container-intro">
          <span v-if="info.titulo" class="title-section">{{this.info.titulo}}</span>
          <div v-else class="bg-skeleton px-2 py-3 w-75 mx-auto"></div>
        </div>
        <div class="col-md-12 text-paragraph container-intro">
                              <p v-if="info.descripcion">{{this.info.descripcion}}</p>
          
          <div v-else class="bg-skeleton px-2 py-5 w-75 mt-2 mx-auto"></div>
        </div>
        <div class="col-md-12 mt-5">
          <div class="row" v-if="onLoad">
            <div class="col-md-12" v-for="i in 4" v-bind:key="'skitem-'+i">
              <PromocionesItemSkeleton />
            </div>
          </div>
          <PromocionesItem v-else :promociones="this.promociones" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Portada from "../components/Portada.vue";
import PromocionesItem from "../components/PromocionesItem.vue";
import PromocionesItemSkeleton from "../components/Skeletons/PromocionesItemSkeleton.vue";
import Api from "../providers/api";
import PageInfo from "../tools/PageInfo";

export default {
  name: "Promociones",
  components: {
    Portada,
    PromocionesItem,
    PromocionesItemSkeleton,
  },
  data() {
    return {
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
      onLoad: true,
      promociones: [],
    };
  },
  methods: {
    getPromociones() {
      const Promocion = Api("promocion");

      Promocion.get().then((response) => {
        this.promociones = response.success ? response.promocion : [];
        this.onLoad = false;
      });
    },
  },
  created: async function () {
    PageInfo.getPageInfo(this.$route.path, resp => {
      this.info = resp
    });
    this.getPromociones();
  },
};
</script>

<style scoped>
.container-intro {
  text-align: center;
  padding-left: 8em;
  padding-right: 8em;
}
.bg-skeleton{
     background-color: #f5f5f5;
}
</style>
