import Api from "../providers/api";
import InfoPagina from "../assets/info/info_pagina.json";

const PageInfo = {
    getPageInfo(path, resp) {
        const Info = Api("info");
        let pageId = this.getPageId(path)
    
        Info.get(pageId).then((response) => {
          if (response.success) {
            resp(response.info);
          }
        });
      },
      getPageId(path) {
        return InfoPagina.filter(function (InfoPagina) {
          return InfoPagina.url == path;
        })[0].id;
      }
}

export default PageInfo;