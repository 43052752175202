<template>
  <div>
    <Portada
      v-if="this.info.imagenes"
      :imagenes="this.info.imagenes"
      :imagenes_m="this.info.imagenes_m"
    />
    <div class="container-width">
      <IntroHome />
      <CuadriculaImagenes />
<!--      <Banner
        url="https://www.entrerios.tur.ar/actividades/turismo-social"
        file_name="banner-home-2.gif"
      />-->
      <Banner
        url="registroalojamientosturísticos.pdf"
        file_name="00_Banner_1100x350px.png"
      />
      <EventosCards />
      <section class="mb-5">
        <div class="pl-lg-5 pr-lg-5 google-map">
          <div class="mx-auto">
            <iframe 
              src="https://www.google.com/maps/d/embed?mid=1NGdrS2pxJeY8v4-AOPQmPtvRJHaqEZ8&ehbc=2E312F&noprof=1"
              width="100%"
              height="480">
            </iframe>
<!--            <iframe
              src="https://www.google.com/maps/d/embed?mid=1bEeUsBtEGHdV2ff4gJbfX01pZxYi0l-x"
              width="100%"
              height="480"
            ></iframe>-->
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Portada from "../components/Portada.vue";
import CuadriculaCuatroImagenes from "../components/CuadriculaCuatroImagenes.vue";
import IntroHome from "../components/IntroHome.vue";
import Banner from "../components/Banner";
import CuadriculaImagenes from "../components/CuadriculaImagenes.vue";
import EventosCards from "../components/EventosCards.vue";
import Api from "../providers/api";
import PageInfo from "../tools/PageInfo";

export default {
  name: "Home",
  components: {
    Portada,
    CuadriculaCuatroImagenes,
    IntroHome,
    CuadriculaImagenes,
    EventosCards,
    Banner,
  },
  data() {
    return {
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
    };
  },
  created: function () {
    PageInfo.getPageInfo(this.$route.path, (resp) => {
      this.info = resp;
    });
  },
};
</script>

<style scoped>
@media only screen and (max-width: 599px) {
  /* mobile */

  .google-map {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}
</style>