<template>
  <div>
    <NavbarSinSlider />
    <PaginaEspecificaSkeleton v-if="onLoad" />
    <PaginaEspecifica v-else :data="promocion" :contacto="false" />
  </div>
</template>

<script>
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import PaginaEspecifica from "../components/PaginaEspecifica.vue";
import PaginaEspecificaSkeleton from "../components/Skeletons/PaginaEspecificaSkeleton.vue"
import Api from "../providers/api";

export default {
  name: "Promocion",
  components: {
    NavbarSinSlider,
    PaginaEspecifica,
    PaginaEspecificaSkeleton
  },
  data() {
    return {
      onLoad: true,
      promocion: {
        id: this.$route.params.id,
        nombre: "",
        descripcion: "",
        detalles: "",
        imagenes: [],
      },
      imagenes: [
        {
          id: "1",
          src:
            "https://gualeguaychutermal.com.ar/wp-content/uploads/fin-a%C3%B1o.jpg",
        },
        {
          id: "2",
          src: "https://www.turismoentrerios.com/colon/img/ini-playas.jpg",
        },
      ],
    };
  },
  methods: {
    getPromocion(id) {
      const Promocion = Api("promocion");
      Promocion.get(id).then(
        response => {
          this.promocion = response.promocion
          this.onLoad = false;
          }
      );
    },
  },
  created: async function () {
    this.getPromocion(this.promocion.id);
    this.promocion.descripcion = this.promocion.descripcion.replace(
      /(?:\\r\\n|\\n)/g,
      "<br>"
    );
    this.promocion.detalles = this.promocion.detalles.replace(
      /(?:\\r\\n|\\n)/g,
      "<br>"
    );
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 2;
  column-gap: 3em;
}
</style>