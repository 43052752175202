<template>
  <div>
    <div class="shadow-container p-5">
      <h4 class="mb-3 text-center text-dark">Información:</h4>
      <div class="text-center mb-3" v-if="this.estrellas">
        <div
          class="d-inline"
          v-for="cant in Number(this.estrellas)"
          :key="cant"
        >
          <i class="fa fa-star text-warning stars" aria-hidden="true"></i>
        </div>
      </div>
      <p class="text-dark" v-if="this.data.direccion">
        <b>
          <i class="fa fa-map-marker text-azul-4 mr-1" aria-hidden="true"></i>
          Dirección:
        </b>
        {{ this.data.direccion }}
      </p>
      <p class="text-dark" v-if="this.data.telefono">
        <b>
          <i class="fa fa-phone text-azul-4 mr-1" aria-hidden="true"></i>
          Teléfono:
        </b>
        <a :href="`tel:${this.data.telefono}`">{{ this.data.telefono }}</a>
      </p>
      <p class="text-dark text-break" v-if="this.data.email">
        <b>
          <i class="fa fa-envelope text-azul-4 mr-1" aria-hidden="true"></i>
          E-mail:
        </b>
        <a :href="`mailto:${this.data.email}`">{{ this.data.email }}</a>
      </p>
      <p class="text-dark" v-if="this.data.fechaInicio">
        <b>
          <i class="fa fa-calendar-o text-azul-4 mr-1" aria-hidden="true"></i>
          Fecha de inicio:
        </b>
        {{ this.getFecha(data.fechaInicio) }}
      </p>
      <p class="text-dark" v-if="this.data.fechaInicio">
        <b>
          <i class="fa fa-clock-o text-azul-4 mr-1" aria-hidden="true"></i>
          Hora de inicio:
        </b>
        {{ getHora(data.fechaInicio) }}
      </p>
      <p class="text-dark" v-if="this.data.fechaFin">
        <b>
          <i class="fa fa-calendar text-azul-4 mr-1" aria-hidden="true"></i>
          Fecha de fin:
        </b>
        {{ this.getFecha(this.data.fechaFin) }}
      </p>
      <p class="text-dark" v-if="this.data.fechaFin">
        <b>
          <i class="fa fa-clock-o text-azul-4 mr-1" aria-hidden="true"></i>
          Hora de Fin:
        </b>
        {{ getHora(this.data.fechaFin) }}
      </p>
      <p class="text-dark" v-if="this.data.web">
        <b>
          <i class="fa fa-globe text-azul-4 mr-1" aria-hidden="true"></i>
          Web:
        </b>
        <a :href="this.data.web" target="_BLANK">Ver página</a>
      </p>
      <div class="social text-center">
        <div class="m-3" v-if="this.data.facebook">
          <a :href="this.data.facebook" target="_blank" class="icon-facebook">
            <i class="fa fa-2x fa-facebook-square" aria-hidden="true"></i>
          </a>
        </div>
        <div class="m-3" v-if="this.data.instagram">
          <a :href="this.data.instagram" target="_blank" class="icon-instagram">
            <i class="fa fa-2x fa-instagram" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
    <div
      class="container p-3 pt-5"
      v-if="
        this.data.caracteristicas && this.data.caracteristicas.length
          ? this.data.caracteristicas.length
          : 0
      "
    >
      <h5>Servicios:</h5>
      <span
        class="border rounded p-1 m-1 font-weight-bold"
        v-for="caracteristica in this.data.caracteristicas"
        :key="caracteristica.id_caracteristica"
        >{{ caracteristica.nombre }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoContacto",
  props: {
    data: Object,
  },
  data() {
    return {
      estrellas: "",
    };
  },
  methods: {
    getFecha(pFecha) {
      fecha = new Date(pFecha);
      return (
        fecha.getDate() +
        "/" +
        (Number(fecha.getMonth()) + 1) +
        "/" +
        fecha.getFullYear()
      );
    },
    getHora(pFecha) {
      fecha = new Date(pFecha);
      return (
        fecha.getHours() +
        ":" +
        `${fecha.getMinutes() < 10 ? "00" : fecha.getMinutes()}` +
        "hs"
      );
    },
  },
  methods: {
    buscarEstrellas() {
      if (this.data.caracteristicas) {
        this.data.caracteristicas.find((e, index) => {
          if (e.id_caracteristica == 45) {
            this.estrellas = e.valor;
            this.data.caracteristicas.splice(index, 1);
          }
        });
      }
    },
    getValidWeb(){
      if (this.data.web) {
        if (!this.data.web.startsWith("http")) {
          this.data.web =  "http://" + this.data.web;
        }
      }
    }
  },
  mounted: function(){
    this.getValidWeb();
  },
  created: function () {
    this.buscarEstrellas();
  },
};
</script>

<style scoped>
.shadow-container {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.2em !important;
  background-color: var(--gris-suave);
}

a {
  color: var(--azul-3);
}

a:hover {
  color: var(--azul-3);
}

/* social media */
.social div {
  display: inline !important;
}

.social div {
  list-style: none;
}

.social div a {
  display: inline-block;
  color: #fff;
  background: #000;
  padding: 10px 10px;
  text-decoration: none;
}

.social div .icon-facebook {
  border-radius: 0.5em;
  background: #3b5998;
}
.social div .icon-instagram {
  border-radius: 0.5em;
  background: #ab1452;
}

.social div a:hover {
  opacity: 0.7;
}

.stars {
  font-size: 1.5em;
}
</style>
