<template>
  <div class="p-1 mb-3">
    <router-link class="router" :to="{path: link}">
      <div class="card mx-auto" style="width: 15rem;">
        <div
          class="image-item card-img-top bg-skeleton"
          v-if="img"
          :style="`background-image: url('${url+img}')`"
        ></div>
        <div
          class="image-item card-img-top"
          v-if="!img"
          style="background-image: url('/empty_image.jpg')"
        ></div>
        <div class="card-body">
          <h5 class="subtipo-title">{{subtipo_nombre}}</h5>
          <h5 class="card-title">{{title}}</h5>
          <p class="card-text">{{description}}</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import config from "../configs/configs";
export default {
  name: "ItemDescriptivo",
  props: {
    img: String,
    subtipo_nombre: String,
    title: String,
    description: String,
    link: String,
  },
  data() {
    return {
      url: config.API.IMG,
    };
  },
};
</script>
<style scoped>
.image-item {
  display: inline-flex;
  height: 12em;
  width: 15em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.text-item-description {
  padding-right: 8em;
}

.text-link a {
  font-weight: bold;
  color: var(--gris-1);
}

.text-azul a {
  text-decoration: none;
}

.card{
  border: none;
}

.router:hover {
  text-decoration: none;
}

.card-body {
  padding: 0.1em 0.5em 0.5em 0.5em;
}

.subtipo-title {
  font-size: 0.9rem;
}

.card-title {
  font-size: 1rem;
  color: black;
  margin-bottom: 0.3rem;
}

.card-text {
  font-size: 0.9em;
  /* puntos suspensivos */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.bg-skeleton{
    background-color: #cacaca;
}


.card:hover .image-item{
  opacity: 0.7;
  transition: ease-in 0.1s;
}
</style>
