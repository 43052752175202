<template>
  <div class="row mx-0" id="eventos-cards">
    <!--<div class="text-center col-md-12 mb-3">
      <span v-if="info.titulo" class="title-section">{{info.titulo}}</span>
      <div v-else class="bg-skeleton px-2 py-3 w-75 mx-auto"></div>
      <div v-if="info.descripcion" class="text-paragraph text-center container-intro mt-5 px-md-10 px-sm-2">{{info.descripcion}}</div>
       <div v-else class="bg-skeleton px-2 py-5 w-75 mt-2 mx-auto"></div>
    </div>-->
    <div v-if="onLoad" class="col-md-12">
      <div class="row">
        <EventoCardSkeleton v-for="i in 4" :key="'sk'+i" class="col-md-6 col-lg-6 col-xl-3" />
      </div>
    </div>
    <div class="col-md-12" v-else>
        <carousel
          :per-page="1"
          :mouse-drag="true"
          :autoplay="true"
          :loop="true"
          autoplayDirection="forward"
          :paginationEnabled="false"
        >
          <slide v-for="numberSlide in numberOfSlides" :key="`slide-${numberSlide}`">
            <div class="row">
              <div
                class="col-md-6 col-lg-6 col-xl-3"
                v-for="numberCard in [4,3,2,1]"
                :key="`card-${numberSlide}-${numberCard}`"
              >
                <div class="card mx-auto">
                  <img
                    class="card-src-top"
                    :src="urlBase+eventos[numberSlide * 4 - numberCard].src"
                    alt="Card image cap"
                  />
                  <div class="card-evento-text">
                    <span>{{eventos[numberSlide * 4 - numberCard].nombre}}</span>
                  </div>
                  <div class="card-body">
                    <p class="card-text">{{eventos[numberSlide * 4 - numberCard].descripcion}}</p>
                  </div>
                  <div class="row">
                    <div class="col-md-6 ml-3">
                      <hr />
                    </div>
                    <div class="col-md-4">
                      <router-link
                        :to="{path: '/agenda'}"
                        class="w3-animate-bottom text-ver-mas my-auto cursor-pointer"
                      >ver más</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import EventoCardSkeleton from "../components/Skeletons/EventoCardSkeleton.vue"
import Config from "../configs/configs";
import Api from "../providers/api";
import PageInfo from "../tools/PageInfo";

export default {
  name: "EventosCards",
  components: {
    Carousel,
    Slide,
    EventoCardSkeleton
  },
  data() {
    return {
      onLoad: true,
      numberOfSlides: 0,
      urlBase: Config.API.IMG,
      eventos: [],
      eventosVisibles: [],
      slidersIterations: 0,
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      }
    };
  },
  methods: {
    //devuelve una la cantidad de iteraciones de sliders para mostrar
    getElementsIterations() {
      let listLength = this.eventos.length;
      this.slidersIterations = Math.floor(listLength / 4);
      this.numberOfSlides = Math.floor(this.eventos.length / 4);
    },
    getEventos() {
      const Evento = Api("proximos-eventos");

      Evento.get().then((response) => {
        this.eventos = response.success ? response.agenda : [];
        this.getElementsIterations();
        this.onLoad = false;
      });
    },
  },
  created: function () {
    let component = 'eventos-card';
    PageInfo.getPageInfo(component, (resp) => {
      this.info = resp;
    });

    this.getEventos();
  },
};
</script>

<style scoped>
#eventos-cards {
  margin-top: 3em;
  margin-bottom: 5em;
}

.card {
  border: none;
  padding: 1em;
  max-width: 18em;
}

.card src {
  max-height: 13em;
  width: auto;
}

.card-src-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 12em;
}

.card-body {
  background-color: #e1e1e1;
}

.card-evento-text {
  background-color: #008dcd;
  height: 2.5em;
  display: table;
}

.card-evento-text span {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  display: table-cell;
  vertical-align: middle;

  /* puntos suspensivos si no entra el  texto */
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10.5em;
}

.card-text {
  height: 6em;

  /* puntos suspensivos */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text-ver-mas {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  color: var(--gris-2);
}

.text-ver-mas:hover {
  text-decoration: underline;
}

.c-slide {
  min-width: 100%;
}

.bg-skeleton{
     background-color: #f5f5f5;
}
</style>
