<template>
  <div>
    <Portada v-if="this.info.imagenes" :imagenes="this.info.imagenes" :imagenes_m="this.info.imagenes_m" />
    <div class="container-width">
      <div class="row m-0">
        <div class="col-md-12 title-pagina">
          <span class="text-title-fullwidth">
            gastro-
            <br />
            <span class="pl-10">nomía</span>
          </span>
        </div>
        <div class="col-md-12 mt-5 px-5">
          <p v-if="info.descripcion">{{ this.info.descripcion }}</p>

          <div v-else class="bg-skeleton px-2 py-5 w-75 mt-2 mx-auto"></div>
        </div>
        <Filtro
          id="filter"
          class="col-12"
          type="servicio"
          servicio="gastronomia"
          :localidades="true"
          :categoria="true"
          :proximidad="true"
        />
        <div
          class="col-md-12 pt-5"
          v-if="!state.onLoad && listGastronomias.length > 0"
        >
          <div class="row">
            <div
              class="col-md-6 col-lg-4 col-xl-3"
              v-for="servicio in listGastronomias"
              v-bind:key="servicio.id"
            >
              <ItemDescriptivo
                :title="servicio.nombre"
                :subtipo_nombre="servicio.subtipo_nombre"
                :direccion="servicio.direccion"
                :img="servicio.src"
                :link="'gastronomia/' + servicio.id"
              />
            </div>
          </div>
          <div class="pagination text-right justify-content-center">
            <ul class="pagination">
              <li class="page-item">
                <span
                  class="page-link np"
                  @click="handleChangePageNP(-1)"
                  :class="pagination.current == 1 ? 'd-none' : ''"
                  >Anterior</span
                >
              </li>

              <li
                class="page-item"
                v-for="page in itemPaginacion"
                :key="'pag' + page"
                :class="[page == pagination.current ? 'actived' : '']"
              >
                <a
                  class="page-link cursor-pointer"
                  @click="handleChangePage(page)"
                  >{{ page }}</a
                >
              </li>

              <li class="page-item" v-if="pagination.totalForPagination > 1">
                <span
                  class="page-link np cursor-pointer"
                  @click="handleChangePageNP(1)"
                  :class="
                    pagination.current == pagination.totalForPagination
                      ? 'd-none'
                      : ''
                  "
                  >Siguiente</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12" v-if="state.onLoad">
          <div class="row">
            <div
              class="col-md-6 col-lg-4 col-xl-3"
              v-for="i in 8"
              v-bind:key="'skitem-' + i"
            >
              <ItemDescriptivoSkeleton />
            </div>
          </div>
        </div>
        <div
          v-if="!state.onLoad && !listGastronomias.length"
          class="text-center mt-3 mx-auto"
        >
          <h4>
            No hay Gastronomias registrados con los filtros seleccionados.
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Portada from "../components/Portada.vue";
import ItemDescriptivo from "../components/ItemDescriptivo.vue";
import Configs from "../configs/configs.js";
import Filtro from "../components/Filtro.vue";
import Api from "../providers/api";
import CercaniasJSON from "../assets/info/cercanias.json";
import ItemDescriptivoSkeleton from "../components/Skeletons/ItemDescriptivoSkeleton.vue";
import PageInfo from "../tools/PageInfo";

export default {
  name: "Gastronomias",
  components: {
    Portada,
    ItemDescriptivo,
    Filtro,
    ItemDescriptivoSkeleton,
  },
  data() {
    return {
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
      state: {
        onLoad: true,
      },
      pagination: {
        total: -1,
        current: 1,
        totalForPagination: -1,
        numberPerPage: 12,
      },
      filter: {
        localidad: -1,
        categoria: -1,
        cercanias: true,
        name: "",
      },
      listaLocalidades: [],
      servicios: [],
    };
  },
  computed: {
    itemPaginacion: function () {
      let min =
        this.pagination.current - 5 > 0 ? this.pagination.current - 5 : 1;
      let max =
        this.pagination.current + 5 > this.pagination.totalForPagination
          ? this.pagination.totalForPagination
          : this.pagination.current + 5;

      let _ = [];

      for (let i = min; i <= max; i++) {
        _.push(i);
      }

      return _;
    },
    listGastronomias: function () {
      const newList = this.servicios
        .filter(
          (x) =>
            (Number(this.filter.localidad) == -1 ||
              (this.filter.cercanias
                ? this.isNear(x.id_localidad)
                : Number(x.id_localidad) == Number(this.filter.localidad))) &&
            (this.filter.categoria == -1 ||
              x.id_subtipo_servicio == this.filter.categoria) &&
            x.nombre.toLowerCase().includes(this.filter.name.toLowerCase())
        )
        .slice(
          (this.pagination.current - 1) * this.pagination.numberPerPage,
          this.pagination.current * this.pagination.numberPerPage
        );
      return newList;
    },
  },
  methods: {
    reloadPagination() {
      const newList = this.servicios.filter(
        (x) =>
          (Number(this.filter.localidad) == -1 ||
            (this.filter.cercanias
              ? this.isNear(x.id_localidad)
              : Number(x.id_localidad) == Number(this.filter.localidad))) &&
          (this.filter.categoria == -1 ||
            x.id_subtipo_servicio == this.filter.categoria) &&
          x.nombre.toLowerCase().includes(this.filter.name.toLowerCase())
      );
      this.pagination.totalForPagination =
        parseInt(newList.length / this.pagination.numberPerPage) +
        (newList.length % this.pagination.numberPerPage ? 1 : 0);
      this.pagination.total =
        parseInt(newList.length / this.pagination.numberPerPage) +
        (newList.length % this.pagination.numberPerPage ? 1 : 0);
    },
    isNear(id) {
      if (Number(id) == Number(this.filter.localidad)) return true;
      else {
        let lista = CercaniasJSON.find(
          (cercania) => cercania.id == this.filter.localidad
        );
        return lista ? lista.cercanias.includes(Number(id)) : false;
      }
    },
    handleChangePageNP(direction) {
      this.pagination.current +=
        direction == -1 && this.pagination.current ? -1 : 0;
      this.pagination.current +=
        direction == 1 &&
        this.pagination.current != this.pagination.totalForPagination
          ? 1
          : 0;
    },
    handleChangePage(page) {
      this.pagination.current = page;
    },
    handleChangeFilterName(name) {
      this.filter.name = name;
      this.reloadPagination();
    },
    handleChangeFilterCercanias(bool) {
      this.filter.cercanias = bool;
      this.reloadPagination();
    },
    handleChangeFilterLocalidad(id) {
      this.filter.localidad = id;
      this.reloadPagination();
    },
    handleChangeFilterCategoria(id) {
      this.filter.categoria = id;
      this.reloadPagination();
    },
    getLocalidades() {
      Api("localidad")
      .get()
      .then((res) => {
        this.listaLocalidades = res.localidad;
      });
    },
  },
  created: function () {
    this.getLocalidades();
    PageInfo.getPageInfo(this.$route.path, (resp) => {
      this.info = resp;
    });
    if (this.$route.query.city) {
      let find = this.listaLocalidades.find(
        (x) =>
          x.nombre.replace(/\s/g, "-").toUpperCase() ==
          this.$route.query.city.toUpperCase()
      );
      if (find) {
        this.filter.localidad = find.id;
      }
    }
    const Gastronomias = Api("servicio?tipo=1");
    Gastronomias.get().then((x) => {
      this.servicios = x.servicio;
      let _ = Object(x.servicio);
      _ = _.filter(
        (x) =>
          (Number(this.filter.localidad) == -1 ||
            (this.filter.cercanias
              ? this.isNear(x.id_localidad)
              : Number(x.id_localidad) == Number(this.filter.localidad))) &&
          (this.filter.categoria == -1 ||
            x.id_subtipo_servicio == this.filter.categoria)
      );
      this.pagination.totalForPagination =
        parseInt(_.length / this.pagination.numberPerPage) +
        (_.length % this.pagination.numberPerPage ? 1 : 0);
      this.pagination.total =
        parseInt(_.length / this.pagination.numberPerPage) +
        (_.length % this.pagination.numberPerPage ? 1 : 0);
      this.state.onLoad = false;
    });
  },
};
</script>

<style scoped>
#spinner {
  color: var(--azul);
}

li.page-item.actived > a {
  background-color: var(--azul-3) !important;
  color: #fff;
}
.page-link.np {
  color: var(--azul-3) !important;
}

.bg-skeleton {
  background-color: #f5f5f5;
}
</style>
