import { render, staticRenderFns } from "./Alojamientos.vue?vue&type=template&id=1df9e61c&scoped=true"
import script from "./Alojamientos.vue?vue&type=script&lang=js"
export * from "./Alojamientos.vue?vue&type=script&lang=js"
import style0 from "./Alojamientos.vue?vue&type=style&index=0&id=1df9e61c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1df9e61c",
  null
  
)

export default component.exports