<template>
  <div class="container-width mb-4">
    <div class="row m-0">
      <div class="col-md-12 title-pagina">
        <span class="text-title-specific"></span>
      </div>
      <div class="col-md-12 p-5 container-intro">
        <span class="text-paragraph"></span>
        <p class="mt-1 bg-skeleton py-2 w-75 h-100"> </p>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-md-7">
        <div class="row mx-0">
          <div class="w-100">
            <div  class="text-center">
              <div
                class="rounded img-thumbnail py-5 img-primary w-100 bg-skeleton border-0 item-load"
              />
              <div class="mini-image-list my-3 border-0">
                <div
                  v-for="image in 3"
                  :key="'skima'+image"
                  class="mx-2 bg-skeleton d-inline-block item-load"
                  style="width: 5em; height: 5em;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div />
      <div class="col-md-5 p-5">
        <div class="h-100 w-100 bg-skeleton"></div>
      </div>
    </div>
      <div class="row mx-0" style="height: 40em">
        <div class="col-xl-6 col-12 h-100 p-5">
          <div class="bg-skeleton h-100 w-100"></div>
        </div>
        <div class="col-xl-6 h-100 p-5 d-none d-xl-block">
          <div class="bg-skeleton h-75 w-100"></div>
        </div>
    </div>
  </div>
</template>

<script>
import InfoContacto from "../InfoContacto.vue";

export default {
  name: "PaginaEspecificaSkeleton",
  components: {
    InfoContacto,
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 2;
  column-gap: 3em;
}

.shadow-container {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.2em !important;
  background-color: var(--gris-suave);
}

.bg-skeleton{
    background-color: #F5F5F5;
}

.img-primary{
    height: 30em;
}


.item-load{
  position: relative;
    overflow: hidden;
}
.item-load::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #F9F9F9 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}
</style>
