<template>
  <div class="row web-container pl-5 pr-5" id="cuadricula-imagenes">
    <div class="col-md-12 mb-3">
      <div class="text-center container-er">
        <span v-if="this.info.titulo" class="title-section">{{
          this.info.titulo
        }}</span>
        <div v-else class="bg-skeleton px-2 py-3 w-75 mx-auto"></div>
        <div
          v-if="this.info.descripcion"
          class="text-paragraph container-intro mt-3 pl-10 pr-10"
        >
          {{ this.info.descripcion }}
        </div>
        <div v-else class="bg-skeleton px-2 py-5 mt-2 w-75 mx-auto"></div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12 p-1 zoom-opacity cursor-pointer">
          <router-link :to="{ path: 'alojamientos' }">
            <div
              class="img-cuadricula"
              :style="`background-image: url('${base_url}images/cuadricula-general/05_760x420_alojamientos.jpg')`"
            ></div>
            <div id="texto-alojamientos">
              <span>alo</span>
              <span>ja</span>
              <span>mien</span>
              <span>tos</span>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 p-1 zoom-opacity cursor-pointer">
          <router-link :to="{ path: 'itinerarios' }">
            <div class="container-img-rectangular">
              <div
                class="img-rectangular"
                :style="`background-image: url('${base_url}images/cuadricula-general/09_380x200_itinerarios.jpg')`"
              ></div>
              <div id="texto-ininerarios">
                <span>iti</span>
                <span>ne</span>
                <span>ra</span>
                <span>rios</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 p-1 zoom-opacity cursor-pointer">
          <router-link :to="{ path: 'gastronomias' }">
            <div class="container-img-rectangular">
              <div
                class="img-rectangular"
                :style="`background-image: url('${base_url}images/cuadricula-general/10_380x200_gastronomia.jpg')`"
              ></div>
              <div id="texto-gastronomia">
                <span>gas</span>
                <span>tro</span>
                <span>no</span>
                <span>mia</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-6 p-1 zoom-opacity cursor-pointer">
          <router-link :to="{ path: 'actividades' }">
            <div class="container-img-rectangular">
              <div
                class="img-rectangular"
                :style="`background-image: url('${base_url}images/cuadricula-general/07_380x200_actividades.jpg')`"
              ></div>
              <div id="texto-actividades">
                <span>acti</span>
                <span>vida</span>
                <span>des</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 p-1 zoom-opacity cursor-pointer">
          <router-link :to="{ path: 'destinos' }">
            <div class="container-img-rectangular">
              <div
                class="img-rectangular"
                :style="`background-image: url('${base_url}images/cuadricula-general/08_380x200_destinos.jpg')`"
              ></div>
              <div id="texto-destinos">
                <span>des</span>
                <span>ti</span>
                <span>nos</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-12 p-1 zoom-opacity cursor-pointer">
          <router-link :to="{ path: 'agenda' }">
            <div
              class="img-cuadricula"
              :style="`background-image: url('${base_url}images/cuadricula-general/06_760x420_agenda.jpg')`"
            ></div>
            <div id="texto-agendas">
              <span>a</span>
              <span>gen</span>
              <span>das</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-md-12 p-1 zoom-opacity cursor-pointer">
      <router-link :to="{ path: 'promociones' }">
        <div
          class="img-larga"
          :style="`background-image: url('${base_url}images/cuadricula-general/11_1420x300_promociones.jpg')`"
        ></div>
        <div id="texto-promociones">
          <span>pro</span>
          <span>mo</span>
          <span>cio</span>
          <span>nes</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import PageInfo from "../tools/PageInfo";
import Configs from "../configs/configs";

export default {
  name: "CuadriculaImagenes",
  data() {
    return {
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
      base_url: Configs.BASE,
    };
  },
  created: function () {
    let component = "cuadricula-imagenes";
    PageInfo.getPageInfo(component, (resp) => {
      this.info = resp;
    });
  },
};
</script>

<style scoped>
#cuadricula-imagenes {
  margin-bottom: 1.5em;
}

.img-cuadricula {
  width: 100%;
  height: 20em;
  background-size: cover;
  filter: brightness(70%);
  transition: filter 0.2s ease-in-out;
}

.container-img-rectangular {
  width: 100%;
  height: 10em;
  background-size: cover;
}

.img-larga {
  width: 100%;
  height: 15em;
  background-size: cover;
  filter: brightness(70%);
  transition: filter 0.2s ease-in-out;
}

.img-rectangular {
  width: 100%;
  height: 100%;
  background-size: cover;
  filter: brightness(70%);
  transition: filter 0.2s ease-in-out;
}

.zoom-opacity:hover .img-larga:hover,
.zoom-opacity:hover .img-cuadricula:hover,
.zoom-opacity:hover .img-rectangular {
  filter: brightness(100%);
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

.col-md-6 {
  padding-left: 0;
  padding-right: 0;
}

#texto-alojamientos {
  top: 25%;
  left: 70%;
  position: absolute;
  border-left: 0.3em solid var(--azul-2);
}

#texto-alojamientos span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3em;
  line-height: 0.9em;
  padding-left: 0.1em;
}

#texto-ininerarios {
  top: 20%;
  left: 65%;
  position: absolute;
  border-left: 0.3em solid var(--azul-2);
}

#texto-ininerarios span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2em;
  line-height: 0.9em;
  padding-left: 0.1em;
}

#texto-gastronomia {
  top: 20%;
  left: 65%;
  position: absolute;
  border-left: 0.3em solid var(--azul-2);
}

#texto-gastronomia span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2em;
  line-height: 0.9em;
  padding-left: 0.1em;
}

#texto-actividades {
  top: 30%;
  left: 65%;
  position: absolute;
  border-left: 0.3em solid var(--azul-2);
}

#texto-actividades span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2em;
  line-height: 0.9em;
  padding-left: 0.1em;
}

#texto-destinos {
  top: 30%;
  left: 65%;
  position: absolute;
  border-left: 0.3em solid var(--azul-2);
}

#texto-destinos span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2em;
  line-height: 0.9em;
  padding-left: 0.1em;
}

#texto-agendas {
  top: 25%;
  left: 70%;
  position: absolute;
  border-left: 0.3em solid var(--azul-2);
}

#texto-agendas span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3.5em;
  line-height: 0.9em;
  padding-left: 0.1em;
}

#texto-promociones {
  top: 15%;
  left: 85%;
  position: absolute;
  border-left: 0.3em solid var(--azul-2);
}

#texto-promociones span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3em;
  line-height: 0.9em;
  padding-left: 0.1em;
}

@media only screen and (max-width: 599px) {
  /* mobile */

  #cuadricula-imagenes {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
  }

  .text-paragraph {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
  }

  #texto-alojamientos {
    top: 25%;
    left: 50%;
  }

  #texto-actividades {
    top: 25%;
    left: 65%;
  }

  #texto-destinos {
    top: 25%;
    left: 65%;
  }

  #texto-ininerarios {
    top: 20%;
    left: 65%;
  }

  #texto-gastronomia {
    top: 20%;
    left: 65%;
  }

  #texto-agendas {
    top: 30%;
    left: 50%;
  }

  #texto-promociones {
    top: 15%;
    left: 55%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  /* tablets */

  #texto-alojamientos {
    left: 55%;
  }

  #texto-actividades {
    top: 25%;
    left: 40%;
  }

  #texto-destinos {
    top: 25%;
    left: 40%;
  }

  #texto-ininerarios {
    top: 17%;
    left: 40%;
  }

  #texto-gastronomia {
    top: 17%;
    left: 45%;
  }

  #texto-agendas {
    top: 30%;
    left: 55%;
  }

  #texto-promociones {
    top: 15%;
    left: 75%;
  }
}

.bg-skeleton {
  background-color: #f5f5f5;
}

@media only screen and (min-width: 1024px) {
  /* desktop */
}
</style>
