<template>
  <div>
    <NavbarSinSlider />
    <div class="container-width">
      <DondeIrCiudad
        :nombre="destino.nombre"
        :descripcion="destino.descripcion"
        :detalles="destino.detalles"
      />
      <div class="row m-0 pl-5 pr-5">
        <GaleryImages
          class="col-lg-7"
          v-if="destino.imagenes && destino.imagenes.length ? destino.imagenes.length : 0"
          :images="destino.imagenes"
        />
        <div class="col-lg-5 p-lg-5 p-md-3 mb-3">
          <InfoContacto :data="this.destino" />
        </div>
      </div>
<!--      <div class="row m-0 pl-5 pr-5 pb-5">
        <div class="col-md-12">
          <span class="text-subtitle my-4">
            qué
            <br />hacer
          </span>
        </div>
      </div>
      <CuadriculaImagenesCiudad />-->
      <div class="pl-5 pr-5 mt-5 mb-5">
        <span class="text-subtitle text-center">Conocé más sobre {{destino.nombre}}</span>
        <div class="web-container mt-4" id="city-introduction">
          <div v-html="destino.detalles" class="text-two-column text-break" id="introduction-paragraph"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DondeIrCiudad from "../components/DondeIrCiudad.vue";
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import CuadriculaImagenesCiudad from "../components/CuadriculaImagenesCiudad.vue";
import GaleryImages from "../components/GaleryImages.vue";
import InfoContacto from "../components/InfoContacto.vue";
import Api from "../providers/api";

export default {
  name: "Destino",
  components: {
    NavbarSinSlider,
    DondeIrCiudad,
    CuadriculaImagenesCiudad,
    GaleryImages,
    InfoContacto,
  },
  data() {
    return {
      destino: {
        id: this.$route.params.id,
        ubicacion_id: 0,
        nombre: "",
        type: "",
        descripcion: "",
        detalles: "",
        direccion: "",
        telefono: "",
        web: "",
        email: "",
        activo: 0,
        imagenes: [],
      },
    };
  },
  methods: {
    getDestino(id) {
      const Destino = Api("destino");
      Destino.get(id).then((response) => {
        if (response.success) {
          response.destino.descripcion = response.destino.descripcion.replace(
            /(?:\\r\\n|\\n)/g,
            "<br>"
          );
          response.destino.detalles = response.destino.detalles.replace(
            /(?:\\r\\n|\\n)/g,
            "<br>"
          );

          this.destino = response.destino;

        }
      });
    },
  },
  created: async function () {
    this.getDestino(this.destino.id);
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 2;
  column-gap: 3em;
}

.text-subtitle {
  line-height: 0.9em;
}

#city-introduction {
  padding-left: 7em;
  padding-right: 7em;
}

@media (max-width: 890px) {
  .text-two-column {
    column-count: 1;
    column-gap: 3em;
    max-width: 100%;
  }

  #city-introduction{
    padding-left: 1em;
    padding-right: 1em;
  }
}
</style>