import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import Contacto from '@/views/Contacto.vue'

import Alojamientos from '@/views/Alojamientos.vue'
import Alojamiento from '@/views/Alojamiento.vue'

import Gastronomias from '@/views/Gastronomias.vue'
import Gastronomia from '@/views/Gastronomia.vue'

import AgenciasDeViaje from '@/views/AgenciasDeViaje.vue'
import AgenciaDeViaje from '@/views/AgenciaDeViaje.vue'

import Transportes from '@/views/Transportes.vue'
import Transporte from '@/views/Transporte.vue'

import OtrosServicios from '@/views/OtrosServicios.vue'
import OtroServicio from '@/views/OtroServicio.vue'

import Actividades from '@/views/Actividades.vue'
import Actividad from '@/views/Actividad.vue'
import TurismoEnNaturaleza from "@/views/TurismoEnNaturaleza.vue"
import ActividadEspecifica from '@/views/ActividadEspecifica.vue'

import Destinos from '@/views/Destinos.vue'
import Destino from '@/views/Destino.vue'

import Itinerarios from '@/views/Itinerarios.vue'
import Itinerario from '@/views/Itinerario.vue'

import Promociones from '@/views/Promociones.vue'
import Promocion from '@/views/Promocion.vue'

import Noticias from '@/views/Noticias.vue'
import Noticia from '@/views/Noticia.vue'

import Agenda from '@/views/Agenda.vue'
import EventoAgenda from '@/views/EventoAgenda.vue'
import Institucional from '@/views/Institucional.vue'

import ProtocolosCovid from '@/views/ProtocolosCovid.vue'

import Error404 from '@/views/Error404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/index.html",
    redirect: "/" 
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: Agenda
  },
  {
    path: '/agenda/:id',
    name: 'EventoAgenda',
    component: EventoAgenda
  },
  {
    path: '/alojamientos',
    name: 'Alojamientos',
    component: Alojamientos
  },
  {
    path: '/alojamientos/:id/:title',
    name: 'Alojamiento',
    component: Alojamiento
  },
  {
    path: '/gastronomias',
    name: 'Gastronomias',
    component: Gastronomias
  },
  {
    path: '/gastronomia/:id',
    name: 'Gastronomia',
    component: Gastronomia
  },
  {
    path: '/agencias-de-viaje',
    name: 'AgenciasDeViaje',
    component: AgenciasDeViaje
  },
  {
    path: '/agencia-de-viaje/:id',
    name: 'AgenciaDeViaje',
    component: AgenciaDeViaje
  },
  {
    path: '/transporte',
    name: 'Transportes',
    component: Transportes
  },
  {
    path: '/transporte/:id',
    name: 'Transporte',
    component: Transporte
  },
  {
    path: '/otros-servicios',
    name: 'OtrosServicios',
    component: OtrosServicios
  },
  {
    path: '/otro-servicio/:id',
    name: 'OtroServicio',
    component: OtroServicio
  },
  {
    path: '/actividades/turismo-en-naturaleza',
    name: 'TurismoEnNaturaleza',
    component: TurismoEnNaturaleza
  },
  {
    path: '/actividad/:id',
    name: 'ActividadEspecifica',
    component: ActividadEspecifica
  },
  {
    path: '/actividades/:any',
    name: 'Actividad',
    component: Actividad
  },
  {
    path: '/actividades',
    name: 'Actividades',
    component: Actividades
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/destino/:id/:title',
    name: 'Destino',
    component: Destino
  },
  {
    path: '/destinos',
    name: 'Destinos',
    component: Destinos
  },
  {
    path: '/itinerarios',
    name: 'Itinerarios',
    component: Itinerarios
  },
  {
    path: '/itinerario/:id/:title',
    name: 'Itinerario',
    component: Itinerario
  },
  {
    path: '/noticias',
    name: 'Noticias',
    component: Noticias
  },
  {
    path: '/noticia/:id',
    name: 'Noticia',
    component: Noticia
  },
  {
    path: '/promociones',
    name: 'Promociones',
    component: Promociones
  },
  {
    path: '/promocion/:id',
    name: 'Promocion',
    component: Promocion
  },
  {
    path: '/institucional',
    name: 'Institucional',
    component: Institucional
  },
  {
    path: '/protocolos-covid',
    name: 'ProtocolosCovid',
    component: ProtocolosCovid
  },
  {
    path: '/error-404',
    name: 'Error404',
    component: Error404
  },
  {
    path: '*',
    name: 'notFound',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router
