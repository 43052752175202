<template>
  <div>
    <div class="col-md-12 px-5 py-5">
      <div class="row text-center">
        <div class="col-md-6 col-lg-3 mx-auto mb-3">
          <h4 class="text-uppercase text-azul">localidad</h4>
          <select
            v-model="filter.localidad"
            class="form-select-er active w-100 w-md-auto"
            v-show="localidades"
            @change="handleSetLocalidad"
          >
            <option value="-1">Todas las localidades</option>
            <option
              v-for="opt in listaLocalidades"
              :key="'loc' + opt.id"
              :value="opt.id"
            >
              {{ opt.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6 col-lg-3 mx-auto mb-3">
          <h4 class="text-uppercase text-azul">Categoria</h4>
          <select
            class="form-select-er active w-100 w-md-auto"
            v-show="categoria"
            v-model="filter.categoria"
            @change="handleSetCategory"
          >
            <option value="-1">Todas las categorias</option>
            <option
              v-for="opt in listaCategorias"
              :key="'cat' + opt.id"
              :value="opt.id"
            >
              {{ opt.nombre }}
            </option>
          </select>
        </div>
        <div
          class="col-md-6 col-lg-3 mx-auto mb-3"
          v-if="listaSubCategorias.length > 0"
        >
          <h4 class="text-uppercase text-azul">Subcategoria</h4>
          <select
            class="form-select-er active"
            v-show="subCategorias"
            v-model="filter.subCategoria"
            @change="handleSetSubCategory"
          >
            <option value="-1">Todas las subcategorias</option>
            <option
              v-show="opt.idParent == filter.categoria"
              v-for="opt in listaSubCategorias"
              :key="'subCat' + opt.id"
              :value="opt.id"
            >
              {{ opt.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6 col-lg-3 mx-auto mb-3" v-if="proximidad">
          <h4 class="text-uppercase text-azul">Incluir destinos cercanos</h4>
          <input
            class="form-select-er form-check-input"
            id="proximidad"
            v-model="filter.proximidad"
            type="checkbox"
            :checked="filter.proximidad"
            @change="handleSetProximidad"
          />
          <label for>Click aquí</label>
        </div>
        <div class="col-md-6 col-lg-3 mx-auto mb-3" v-if="Estrellas">
          <h4 class="text-uppercase text-azul">Estrellas</h4>
          <select
            class="form-select-er active"
            v-show="Estrellas"
            v-model="filter.estrellas"
            @change="handleSetEstrellas"
          >
            <option value="-1">Todas las estrellas</option>
            <option value="1">1 Estrella</option>
            <option value="2">2 Estrellas</option>
            <option value="3">3 Estrellas</option>
            <option value="4">4 Estrellas</option>
            <option value="5">5 Estrellas</option>
          </select>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <i class="fa fa-search mr-2 my-auto text-azul" aria-hidden="true"></i>
      <input
        class="w-75 shadow-sm rounded"
        id="filter-search-bar"
        type="text"
        name=""
        v-model="filter.name"
        placeholder="Buscar..."
        v-on:input="handleSetFilterName()"
      />
    </div>
  </div>
</template>

<script>
import categoriasActividadesJSON from "../assets/info/actividades.json";
import categoriaServiciosJSON from "../assets/info/servicios.json";
import Api from "../providers/api";

export default {
  name: "Filtro",
  props: {
    type: String,
    servicio: String,
    localidades: Boolean,
    categoria: Boolean,
    subCategorias: Boolean,
    proximidad: Boolean,
    actividad: String,
    Estrellas: Boolean,
  },
  data() {
    return {
      listaCategorias: [],
      listaSubCategorias: [],
      listaLocalidades: [],
      filter: {
        estrellas: -1,
        subCategoria: -1,
        localidad: -1,
        categoria: -1,
        proximidad: false,
        name: "",
      },
    };
  },
  methods: {
    handleSetFilterName() {
      this.$parent.handleChangeFilterName(this.filter.name);
    },
    handleSetEstrellas() {
      this.$parent.handleChangeFilterEstrellas(this.filter.estrellas);
    },
    handleSetSubCategory() {
      this.$parent.handleChangeFilterSubCategoria(this.filter.subCategoria);
    },
    handleSetLocalidad() {
      this.$parent.handleChangeFilterLocalidad(this.filter.localidad);
    },
    handleSetCategory() {
      if (this.subcategorias) {
        this.filter.subCategoria = -1;
        this.$parent.handleChangeFilterSubCategoria(this.filter.subCategoria);
      }
      this.$parent.handleChangeFilterCategoria(this.filter.categoria);
    },
    handleSetProximidad() {
      this.$parent.handleChangeFilterCercanias(this.filter.proximidad);
    },
    getLocalidades() {
      Api("localidad")
        .get()
        .then((res) => {
          this.listaLocalidades = res.localidad;
          this.filterCityUrl()
        });
    },
    filterCityUrl() {
      let cityUrl = this.$route.query.city;

      if (cityUrl) {
        let find = this.listaLocalidades.find(
          (x) =>
            x.nombre.replace(/\s/g, "-").toUpperCase() == cityUrl.toUpperCase()
        );

        if (find) {
          this.$parent.handleChangeFilterLocalidad(find.id);
          this.filter.localidad = find.id;
          this.handleSetProximidad()
        }
      }
    },
  },
  mounted: function () {
    this.getLocalidades();
  },
  created: function () {
    if (this.type == "actividad") {
      if (this.subCategorias) {
        categoriasActividadesJSON.categorias.forEach((e) => {
          if (e.url == this.actividad) {
            this.listaCategorias.push({
              id: e.id,
              nombre: e.nombre,
            });
            this.listaSubCategorias.push(
              ...e.subcategorias.map((f) => {
                return { ...f, idParent: e.id };
              })
            );
          }
        });
      } else {
        this.listaCategorias = categoriasActividadesJSON.categorias.find(
          (x) => x.url == this.actividad
        ).subcategorias;
      }
    } else {
      this.listaCategorias = categoriaServiciosJSON.categorias.find(
        (x) => x.url == this.servicio
      ).subcategorias;
    }
  },
};
</script>

<style scoped>
#spinner-localidad {
  font-size: 0.8em;
  height: 0.9em;
  width: 0.9em;
}

#filter-search-bar {
  height: 2em;
  max-width: 20em;
}
</style>
