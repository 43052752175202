<template>
  <div>
    <div id="app">
    <div class="colors-border" id="border-div"></div>
      <router-view :key="$route.fullPath" />
    </div>
    <SocialMedia />
    <Footer />
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import SocialMedia from "./components/SocialMedia.vue";

export default {
  name: "App",
  components: {
    Footer,
    SocialMedia
  }
};
</script>

<style>
@import "./assets/css/colors.css";
@import "./assets/css/overview.css";
@import "./assets/css/texts.css";
@import "./assets/css/forms.css";
@import "./assets/css/styles.css";
@import "./assets/css/effects.css";
@import "./assets/vendor/font-awesome-4.7.0/css/font-awesome.min.css";
@import "./assets/font/stylesheet.css";


*:not(i) {
  font-family: 'Avenir Next Cyr' !important;
}

#app:not(i) {
  font-family: 'Avenir Next Cyr' !important;
  position: relative;
  min-height: 110em;
}

.container-width{
  max-width: 1280px;
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>

<style scoped>
.colors-border {
  border-left: 1em solid;
  border-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 5%,
      var(--azul-1) 5%,
      var(--azul-1) 20%,
      var(--azul-2) 20%,
      var(--azul-2) 35%,
      var(--azul-4) 35%,
      var(--azul-4) 50%,
      var(--verde-2) 50%,
      var(--verde-2) 65%,
      var(--verde-1) 65%,
      var(--verde-1) 80%,
      var(--naranja) 80%,
      var(--naranja) 95%,
      rgba(0, 0, 0, 0) 95%
    )
    5;
}

#border-div {
  position: absolute;
  left: 0;
  width: 1em;
  height: 50%;
  z-index: 1;
  top: 50em;
}
</style>
