<template>
  <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" v-if="!hideCloseBtn">
          <button type="button" class="close" @click="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
            <i v-if="icon && icon == 'error'" class="fa fa-exclamation-circle fa-4x text-danger" aria-hidden="true"></i>
            <i v-if="icon && icon == 'check'" class="fa fa-check-circle-o fa-4x text-success" aria-hidden="true"></i>
          <h4 class="mt-3">{{title}}</h4>
          <p class="mt-3">{{message}}</p>
        </div>
        <div class="modal-footer mx-auto">
          <span v-if="displayCancelBtn" class="btn btn-secondary" @click="closeModal()">Cancelar</span>
          <div v-if="displayConfirmBtn">
            <span v-if="handleLoad" class="btn btn-success">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </span>
            <span v-else class="btn btn-success" @click="acceptButton()">Confirmar</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
    message: String,
    accept: String,
    handleLoad: Boolean,
    displayConfirmBtn: Boolean,
    displayCancelBtn: Boolean,
    hideCloseBtn: Boolean,
    icon: String,
  },
  data() {
    return {};
  },
  methods: {
    acceptButton() {
      this.$parent[this.accept](() => {
        this.closeModal();
      });
    },
    closeModal() {
      document.querySelector(`#${this.$attrs.id}`).classList.remove("fade");
      document.querySelector(`#${this.$attrs.id}`).classList.remove("show");
      document.querySelector(`#${this.$attrs.id}`).style.display = "none";
    },
  },
};
</script>

<style scoped lang="css">
.modal-header {
  border-bottom: 0px;
}

.modal-footer {
  border-top: 0px;
}

.text-correct {
  color: #1d6b2f;
}

.text-error {
  color: #cc253c;
}

.icon-size {
  font-size: 4em;
}
</style>