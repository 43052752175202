<template>
  <div>
    <div class="promocion-item row mb-5 mt-5" v-for="promocion in promociones" :key="promocion.id">
      <div class="col-md-6 col-lg-4">
        <div class="img-cuadrada" v-if="promocion.src"  :style="`background-image: url('${url + promocion.src}')`"></div>
        <div class="img-cuadrada" v-if="!promocion.src"  :style="`background-image: url('/empty_image.jpg')`"></div>
      </div>
      <div class="col-md-6 col-lg-8">
        <div class="row">
          <div class="col-md-12 text-center pt-3">
            <span class="promocion-title">{{promocion.nombre}}</span>
          </div>
          <div class="col-md-12 text-center mt-3">
            <span class="promocion-description">{{promocion.descripcion}}</span>
          </div>
          <div class="col-md-12 text-center mt-3 promocion-url">
            <div class="row">
              <div class="col-md-6 col-lg-8 ml-5">
                <hr />
              </div>
              <div class="col-md-4 col-lg-2">
                <router-link
                  :to="{path: `${'/promocion/' + promocion.id}` }"
                  class="text-ver-mas my-auto cursor-pointer"
                >Ver más</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../configs/configs";
export default {
  name: "PromocionesItem",
  props: {
    promociones: Array,
  },
  data() {
    return {
      url: config.API.IMG,
    };
  },
};
</script>

<style scoped>
.img-cuadrada {
  width: auto;
  min-width: 18em;
  height: 18em;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(90%);
}

.promocion-title {
  font-size: 2em;
  font-weight: bold;
  color: var(--azul-4);

  /* puntos suspensivos */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.promocion-description{
  color: var(--gris-4);

  /* puntos suspensivos */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.promocion-url {
  position: absolute;
  bottom: 1em;
}

.text-ver-mas {
  font-weight: bold;
  color: var(--azul-4);
}

.promocion-item:hover .img-cuadrada{
  opacity: 0.7;
  transition: ease-in 0.1s;
}

@media (min-width: 700px) and (max-width: 1080px) { 
}

@media (max-width: 414px) {
  .img-cuadrada{
    min-width: 12em;
    height: 9em;
  }

  .promocion-description{
    min-height: 21em;
  }
}

@media (max-width: 890px) { 
  .promocion-description{
    min-height: 15em;

    /* puntos suspensivos */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  }
}


</style>
