<template>
  <div class="container-width mb-4">
    <div class="row m-0 pl-5 pr-5 pb-5">
      <div class="col-md-12 title-pagina">
        <span class="text-title-fullwidth d-inline">{{data.nombre}}</span>
      </div>
      <div class="col-md-12 p-5 container-intro">
        <span class="text-paragraph" v-html="data.descripcion"></span>
        <p class="mt-1" v-if="this.data.fecha">(Publicación: {{this.getFecha(this.data.fecha)}})</p>
      </div>
    </div>
    <div class="row m-0 pl-5 pr-5" v-if="contacto">
      <GaleryImages
        class="col-md-7"
        v-if="data.imagenes && data.imagenes.length ? data.imagenes.length : 0"
        :images="data.imagenes"
      />
      <div class="col-md-5 p-lg-5 p-md-3">
        <InfoContacto :data="data" />
      </div>
    </div>
    <div class="row m-0 pl-5 pr-5" v-if="!contacto">
      <GaleryImages
        class="col-md-12"
        v-if="data.imagenes && data.imagenes.length ? data.imagenes.length : 0"
        :images="data.imagenes"
      />
    </div>
    <div class="m-0 p-5" v-if="data.detalles">
      <span class="text-center title-section mb-3">Detalles</span>
      <p class="text-two-column text-break" v-html="data.detalles.replace(/\\r\\n/g,'<br>')"></p>
    </div>
  </div>
</template>

<script>
import GaleryImages from "../components/GaleryImages.vue";
import InfoContacto from "../components/InfoContacto.vue";

export default {
  name: "PaginaEspecifica",
  components: {
    GaleryImages,
    InfoContacto,
  },
  props: {
    data: Object,
    contacto: Boolean,
  },
  methods: {
    getFecha(fecha) {
      fecha = new Date(fecha);
      return (
        fecha.getDate() +
        "/" +
        (Number(fecha.getMonth()) + 1) +
        "/" +
        fecha.getFullYear()
      );
    },
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 1;
  column-gap: 3em;
}

.shadow-container {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.2em !important;
  background-color: var(--gris-suave);
}

@media (max-width: 890px) {
  .text-two-column {
    column-count: 1;
    column-gap: 3em;
    max-width: 100%;
  }
}
</style>
