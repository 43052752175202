import Config from "../configs/configs"

const api = resource => {
    const url = `${Config.API.BASE}${resource}`;
    return({
        get: (id = "") => 
            fetch(`${url}${id ? `/${id}` : ''}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then(x => x.json())
            .catch(err => console.log(err)),
        patch: (id, body = {}) => 
            fetch(`${url}/${id}`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            .then( x => x.json()),
        post: (body = {}) => 
        fetch(`${url}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
        .then( x => x.json())
    })
}

export default api;