const URL = 'https://api.entrerios.tur.ar'

const Configs = {
    ENV_MODE: true,
    API: {
        BASE: URL + '/api/public/',
        IMG: URL + '/assets/uploads/',
        VIDEO: URL + '/assets/videos/'
    },
    BASE: '/'
}

export default Configs;