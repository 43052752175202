<template>
  <div>
    <Portada v-if="this.info.imagenes" :imagenes="this.info.imagenes" :imagenes_m="this.info.imagenes_m"/>
    <div class="container-width">
      <div class="row m-0 pl-5 pr-5">
        <div class="col-md-12 title-pagina">
          <span class="text-title-fullwidth">{{tituloPagina}}</span>
        </div>
        <div class="col-md-12 mt-5 mb-5 container-intro text-center">
          <span v-if="info.titulo" class="title-section">{{this.info.titulo}}</span>
          <div v-else class="bg-skeleton px-2 py-3 w-75 mx-auto"></div>
        </div>
      </div>

      <div class="row" v-if="noticias.onLoad">
        <div class="col-md-6 col-lg-4 col-xl-3" v-for="i in 8" v-bind:key="'skitem-'+i">
          <ItemDescriptivoSkeleton />
        </div>
      </div>

      <div class="web-container p-5">
        <div v-if="this.noticias.onLoad == false && this.noticias.lista.length > 0">
          <div class="row">
            <div class="col-md-6 col-lg-4 col-xl-3" v-for="noticia in this.noticias.lista" v-bind:key="noticia.id">
              <ItemDescriptivo
                :title="noticia.nombre"
                :description="noticia.descripcion"
                :img="noticia.src"
                :link="'noticia/' + noticia.id"
              />
            </div>
          </div>
        </div>
        <div
          v-if="this.noticias.onLoad == false && this.noticias.lista.length == 0"
          class="text-center"
        >
          <h4>No hay noticias registradas.</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Portada from "../components/Portada.vue";
import ItemDescriptivo from "../components/ItemDescriptivo.vue";
import ItemDescriptivoSkeleton from "../components/Skeletons/ItemDescriptivoSkeleton.vue";
import Api from "../providers/api";
import PageInfo from "../tools/PageInfo";

export default {
  name: "Noticias",
  components: {
    Portada,
    ItemDescriptivo,
    ItemDescriptivoSkeleton,
  },
  data() {
    return {
      tituloPagina: "Noticias",
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
      noticias: {
        onLoad: false,
        lista: [],
      },
    };
  },
  methods: {
    getNoticias() {
      this.noticias.onLoad = true;
      const Noticia = Api("noticia");

      Noticia.get().then((response) => {
        this.noticias.lista = response.success ? response.noticia : [];
        this.noticias.onLoad = false;
      });
    },
  },
  created: async function () {
    PageInfo.getPageInfo(this.$route.path, (resp) => {
      this.info = resp;
    });
    this.getNoticias();
  },
};
</script>

<style scoped>
.bg-skeleton{
     background-color: #f5f5f5;
}
</style>
