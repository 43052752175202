<template>
  <div class="row my-2">
      <div class="col-md-4" style="height:18em"><div class="bg-skeleton h-100 w-100 item-load"></div></div>
      <div class="col-md-8 h-100 ">
          <h1 class="bg-skeleton py-4"></h1>
          <h4 class="bg-skeleton w-75 py-3"></h4>
      </div>
  </div>
</template>

<script>
export default {
  name: "PromocionesItemSkeleton",
  props: {

  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
.image-item {
  background-color: #F5F5F5;
  display: inline-flex;
  height: 12em;
  width: 15em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.text-item-description {
  padding-right: 8em;
}

.text-link a {
  font-weight: bold;
  color: var(--gris-1);
}

.text-azul a {
  text-decoration: none;
}

.card{
  border: none;
}

.router:hover {
  text-decoration: none;
}

.card-body {
  padding: 0.1em 0.5em 0.5em 0.5em;
}

.subtipo-title {
  font-size: 0.9rem;
}

.card-title {
  font-size: 1rem;
  color: black;
  margin-bottom: 0.3rem;
}

.card-text {
  font-size: 0.9em;
  /* puntos suspensivos */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.bg-skeleton{
    background-color: #f5f5f5;
}
.card-text{
    height: 1.5em;
    margin-left: 0;
}
.item-load{
  position: relative;
    overflow: hidden;
}
.item-load::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #F9F9F9 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}
</style>