<template>
  <div>
    <NavbarSinSlider />
    <section id="donde-ir">
      <div class="container-er">
        <div class="row m-0 pl-5 pr-5">
          <div class="col-md-12 title-pagina">
            <span class="text-title-fullwidth">
              proto-
              <br />
              <span class="pl-10">colos</span>
            </span>
          </div>
          <div class="col-md-12 mt-5 mb-5 text-paragraph container-intro">
            <p>
              Protocolos Covid 19 elaborados por la Secretaría de turismo para
              los prestadores de servicio de la provincia de Entre Ríos.
            </p>
          </div>
          <div class="col-md-12">
            <div id="protocolos" class="mb-5">
              <h1 class="text-azul w-900 lh-06 mb-5">
                Protocolos Provinciales
              </h1>
              <div
                class="pl-5"
                v-for="protocolo in this.protocolos"
                :key="protocolo.id"
              >
                <a
                  class="font-weight-bold"
                  :href="`${urlbase}protocolos_covid/${protocolo.url}.pdf`"
                  target="_blank"
                  >- {{ protocolo.nombre }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3 mb-5 text-paragraph">
            <p>
              Por otra parte, la provincia ha trabajado de manera con el
              Ministerio de Turismo y Deportes de la Nación de manera conjunta,
              para la elaboración de protocolos aplicables a diferentes
              actividades tales como Alojamientos turísticos, Establecimientos
              gastronómicos, Agencias de viajes y turismo, Turismo de Reuniones,
              Prestadores Turísticos -anexos Guías de Turismo, Enoturismo,
              Turismo Rural, Parques temáticos y atracciones y Turismo Aventura.
              Los que se encuentran disponibles para su descarga en el siguiente
              enlace
              <a
                href="https://www.argentina.gob.ar/turismoydeportes/protocolos-covid-turismo-deportes"
                target="_BLANK"
                >click aquí.</a
              >
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import Configs from "../configs/configs.js";

export default {
  name: "ProtocolosCovid",
  components: {
    NavbarSinSlider,
  },
  data() {
    return {
      urlbase: "",
      protocolos: [],
    };
  },
  methods: {
    getProtocolos(){
      fetch("/data/protocolos_covid.json")
      .then((r) => r.json())
      .then(
        (json) => {
          this.protocolos = json;
        },
        (response) => {
          console.log("Error loading protocols");
        }
      );
    }
  },
  async mounted() {
    this.getProtocolos();
  },
  created() {
    this.urlbase = location.origin + Configs.BASE;
    console.log(this.urlbase);
  },
};
</script>

<style scoped>
.title-section {
  font-size: 1.5em;
}

@media (max-width: 414px) {
  .title-pagina {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }

  #protocolos h1 {
    line-height: 1em;
  }
}
</style>
