<template>
  <div>
    <div class="web-container p-5">
      <div class="row text-center">
        <div class="col-md-12 mb-5">
          <span v-if="this.info.titulo" class="title-section">{{
            this.info.titulo
          }}</span>
          <div v-else class="bg-skeleton px-2 py-3 w-75 mx-auto"></div>
        </div>
        <div class="col-md-12 mb-5">
          <CuadriculaCuatroImagenes />
        </div>
        <div
          v-if="this.info.descripcion"
          class="col-md-12 text-paragraph"
          id="text-intro"
        >
          {{ this.info.descripcion }}
        </div>
        <div v-else class="bg-skeleton px-2 py-5 w-75 mx-auto"></div>
        <div class="col-md-12 mt-5">
          <span class="title-section"
            >Entre Ríos, un deseo compartido</span
          >
          <!-- <Video360 imageName="home.jpg" /> -->
          <div class="embed-responsive embed-responsive-16by9 mt-3">
            <iframe
              class="embed-responsive-item"
              :src="`${url_base_local}home.mp4`"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CuadriculaCuatroImagenes from "../components/CuadriculaCuatroImagenes.vue";
import Video360 from "../components/Video360.vue";
import PageInfo from "../tools/PageInfo";
import Configs from "../configs/configs";

export default {
  name: "IntroHome",
  components: {
    CuadriculaCuatroImagenes,
    Video360,
  },
  data() {
    return {
      info: {
        titulo: "",
        descripcion: "",
        imagenes: [],
      },
      base_url: Configs.BASE,
      url_base_local: "",
    };
  },
  created: function () {
    this.url_base_local = Configs.API.VIDEO;
    let component = "intro-home";
    PageInfo.getPageInfo(component, (resp) => {
      this.info = resp;
    });
  },
};
</script>

<style scoped>
#donde-ir {
  margin-top: 5em;
  margin-bottom: 1.5em;
}

#banner-home {
  height: 10em;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#interactive-map {
  background-image: url("https://www.entrerios.gov.ar/ambiente/userfiles/images/otras_imagenes/mapa_politico%20con%20departamentos.jpg");
  background-repeat: no-repeat;
  width: auto;
  height: 40em;
  background-position: right;
}

#text-intro {
  padding-left: 8em;
  padding-right: 8em;
}

@media only screen and (max-width: 599px) {
  /* mobile */
  #text-intro {
    padding-left: 2em;
    padding-right: 2em;
  }

  #banner-home {
    height: 4em;
  }
}

@media only screen and (min-width: 600px) {
  /* tablets */

  #text-intro {
    padding-left: 6em;
    padding-right: 6em;
  }

  #banner-home {
    height: 8em;
  }
}

@media only screen and (min-width: 1024px) {
  /* desktop */
}

.bg-skeleton {
  background-color: #f5f5f5;
}
</style>
