<template>
  <div class="container">
    <div
      class="banner-home my-5 cursor-pointer"
      target="_BLANK"
      :style="`background-image: url('${base_url + file_name}')`"
      @click="handleClick"
    ></div>
  </div>
</template>

<script>
import Configs from "../configs/configs";

export default {
  name: "Banner",
  props: {
    url: String,
    file_name: String,
  },
  data() {
    return {
      base_url: Configs.BASE,
    };
  },
  methods: {
    handleClick() {
      window.open(this.url);
    },
  },
};
</script>

<style scoped>
.banner-home {
  height: 10em;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 320px) {
  /* mobile */
  .banner-home {
    height: 2em;
  }
}

@media only screen and (min-width: 321px) and (max-width: 599px) {
  /* mobile */
  .banner-home {
    height: 2.5em;
  }
}

@media only screen and (min-width: 600px) {
  /* tablets */
  .banner-home {
    height: 4.5em;
  }
}

@media only screen and (min-width: 1024px) {
  /* desktop */
  .banner-home {
    height: 8em;
  }
}
</style>
