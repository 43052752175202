<template>
  <div id="footer" class="row m-0 text-center">
    <div class="col-lg-4 border-r my-auto">
      <span class="mb-1">secretaría de turismo</span>
      <span>secretaría general de la gobernación</span>
      <!--<span>turismo y desarrollo económico</span>-->
      <span class="text-aguamarina mt-1">gobierno de entre rios</span>
    </div>
    <div class="col-lg-4 border-r my-auto">
      <span class="mb-1">laprida 5. paraná - cp 3100</span>
      <span>teléfono: +54 343 4220722</span>
      <span class="text-aguamarina mt-1">infoturismoer@gmail.com</span>
    </div>
    <div class="col-lg-4 my-auto logos">
      <div class="row">
        <div class="col-md-6 p-0">
          <div id="logo_cfi" class="mx-auto"></div>
        </div>
        <div class="col-md-6 p-0">
          <div id="logo" class="mx-auto"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
#footer {
  background-color: var(--gris-3);
  height: 12em;
  color: var(--gris-1);
}

#footer div span {
  display: block;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
}

#footer div {
  height: 4em;
}

.text-aguamarina {
  color: var(--azul-3) !important;
}

#logo {
  background-image: url("../assets/logo.png");
  background-size: cover;
  height: 4em;
  max-width: 13.5em;
}

#logo_cfi {
  background-image: url("../assets/logo_cfi.png");
  background-size: cover;
  height: 4em;
  max-width: 9.5em;
}

.border-r {
  border-right: 0.1em solid var(--azul-3);
}

@media only screen and (max-width: 599px) {
  /* mobile */
  #footer {
    height: 30em;
    
  }

  #footer div {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  .logos{
    margin: 0 !important;
    height: 15em !important;
  }

  .border-r{
    border: none;
  }
}

@media only screen and (min-width: 600px) {
  /* tablets */

  #footer {
    height: 20em;
  }

  .border-r {
    border: none;
  }
}
</style>
