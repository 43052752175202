<template>
  <div>
    <section id="donde-ir-ciudad" class="pl-5 pr-5">
      <div class="web-container">
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="text-title-fullwidth">{{nombre}}</span>
          </div>
          <div class="col-md-12 p-5 container-intro" id="city-introduction">
            <span v-html="descripcion" class="text-paragraph" id="introduction-title"></span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "DondeIrCiudad",
  props: {
    nombre: String,
    descripcion: String,
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped>
#donde-ir-ciudad {
  margin-top: 5em;
  margin-bottom: 3em;
}
.text-numeral {
  font-size: 4em;
  display: inline;
  margin-right: 0.5em;
}
.text-subtitle {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 0.9em;
  font-size: 4em;
  display: inline;
}

#city-introduction {
  padding-left: 15em;
  padding-right: 15em;
}

#introduction-title {
  font-weight: bold;
  font-size: 1.5em;
  display: block;
  color: var(--gris-4);
}

#introduction-paragraph {
  display: block;
  margin-top: 1.5em;
  color: var(--gris-4);
}
</style>
