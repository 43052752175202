<template>
  <div class="w-100">
    <div v-if="urlImages && urlImages.length ? urlImages.length : 0" class="text-center">
      <img
        :src="urlBase+urlImages[imageSelected].src"
        class="img-fluid rounded img-thumbnail"
        alt="Responsive image"
      />
      <div class="mini-image-list my-3">
        <img
          v-for="(image, index) in images"
          :key="image.src"
          :src="urlBase+image.src"
          @mouseover="handleChangeImage(index)"
          class="img-thumbnail mx-2"
          :class=" index == imageSelected ? 'border-primary' : '' "
          style="width: 5em; height: 5em"
        />
      </div>
    </div>
  </div>
</template>

<script>

import Config from "../configs/configs"

export default {
  props: {
    images: Array,
  },
  data() {
    return {
      imageSelected: 0,
      urlImages: [],
      urlBase: Config.API.IMG
    };
  },
  created: function () {
    this.urlImages = this.images;
  },
  methods:{
      handleChangeImage(i){
          this.imageSelected = i;
      }
  }
};
</script>