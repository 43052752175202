<template>
  <div>
    <NavbarSinSlider />
    <PaginaEspecificaSkeleton v-if="onLoad" />
    <PaginaEspecifica v-else :data="alojamiento" :contacto="true" />
  </div>
</template>

<script>
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import PaginaEspecifica from "../components/PaginaEspecifica.vue";
import PaginaEspecificaSkeleton from "../components/Skeletons/PaginaEspecificaSkeleton.vue";

import Api from "../providers/api";

export default {
  name: "Alojamiento",
  components: {
    NavbarSinSlider,
    PaginaEspecifica,
    PaginaEspecificaSkeleton,
  },
  data() {
    return {
      onLoad: true,
      alojamiento: {
        imagenes: [],
      },
      imagenes: [
        {
          id: "1",
          src:
            "https://gualeguaychutermal.com.ar/wp-content/uploads/fin-a%C3%B1o.jpg",
        },
        {
          id: "2",
          src: "https://www.turismoentrerios.com/colon/img/ini-playas.jpg",
        },
      ],
    };
  },
  methods: {
    getalojamiento(id) {
      const Servicio = Api("servicio");

      Servicio.get(id).then((response) => {
        response.servicio.descripcion = response.servicio.descripcion.replace(
          /(?:\\r\\n|\\n)/g,
          "<br>"
        );
        this.alojamiento = response.servicio;
        this.onLoad = false;
      });
    },
  },
  created: async function () {
    this.getalojamiento(this.$route.params.id);
  },
};
</script>

<style scoped>
.text-two-column {
  column-count: 2;
  column-gap: 3em;
}

.shadow-container {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.2em !important;
  background-color: var(--gris-suave);
}
</style>
