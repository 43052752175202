<template>
  <div>
    <NavbarSinSlider />
    <div class="container-width">
      <div class="row m-0 px-lg-5 px-md-2 px-1 pb-5">
        <div class="col-md-12 title-pagina-specific">
          <span class="text-title-fullwidth">Institucional</span>
        </div>
        <div class="container mt-5">
          <div class="col-md-12 shadow-container p-md-5 p-1">
            <span class="title-section pl-3">Autoridades</span>
            <div v-if="onLoad">
              <InstitucionalSkeleton border="rounded" />
              <InstitucionalSkeleton border="rounded" />
            </div>
            <div v-else>
              <div
                class="row autoridad-container mt-3"
                v-for="autoridad in this.autoridades"
                :key="autoridad.id"
              >
                <div class="col-md-3 col-lg-2">
                  <div
                    class="image-item rounded-circle"
                    :style="`background-image: url('${urlBase + autoridad.src}')`"
                  ></div>
                </div>
                <div class="col-md-9 col-lg-10">
                  <div class="row pt-3">
                    <div class="col-md-12">
                      <h3>{{autoridad.puesto}}</h3>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-8">
                          <h5>{{autoridad.nombre}}</h5>
                        </div>
                        <div class="col-md-4 text-right redes-sociales">
                          <a
                            v-if="autoridad.facebook"
                            :href="autoridad.facebook"
                            target="_blank"
                            class="icon-facebook p-1"
                          >
                            <i class="fa fa-2x fa-facebook-square" aria-hidden="true"></i>
                          </a>
                          <a
                            v-if="autoridad.twitter"
                            :href="autoridad.twitter"
                            target="_blank"
                            class="icon-twitter p-1"
                          >
                            <i class="fa fa-2x fa-twitter-square" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-5 shadow-container p-md-5 p-1">
            <span class="title-section pl-3">Sedes</span>
            <div v-if="onLoad">
              <InstitucionalSkeleton />
              <InstitucionalSkeleton />
            </div>
            <div v-else>
              <div class="row autoridad-container mt-3" v-for="sede in this.sedes" :key="sede.id">
                <div class="col-md-3 col-lg-2">
                  <div
                    class="image-item rounded"
                    :style="`background-image: url('${urlBase + sede.src}')`"
                  ></div>
                </div>
                <div class="col-md-9 col-lg-10">
                  <div class="row pt-3">
                    <div class="col-md-12">
                      <h3>{{sede.nombre}}</h3>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-12 col-lg-8 text-break">
                          <div v-if="sede.direccion" class="col-md-12">
                            <font-awesome-icon
                              size="lg"
                              prefix="far"
                              icon="map-marker-alt"
                              class="text-azul-4 mr-2"
                            />
                            <!-- <b>Dirección:</b> -->
                            {{sede.direccion}}
                          </div>
                          <div v-if="sede.email" class="col-md-12">
                            <font-awesome-icon
                              size="lg"
                              prefix="fas"
                              icon="envelope"
                              class="text-azul-4 mr-2"
                            />
                            <!-- <b>Email</b> -->
                            {{sede.email}}
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-4">
                          <div v-if="sede.telefono" class="col-md-12">
                            <font-awesome-icon
                              size="lg"
                              prefix="fas"
                              icon="phone-square-alt"
                              class="text-azul-4 mr-2"
                            />
                            <!-- <b>Teléfono:</b> -->
                            {{sede.telefono}}
                          </div>
                          <div v-if="sede.celular" class="col-md-12">
                            <font-awesome-icon
                              size="lg"
                              prefix="fas"
                              icon="mobile-alt"
                              class="text-azul-4 mr-2"
                            />
                            <!-- <b>Celular:</b> -->
                            {{sede.celular}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarSinSlider from "../components/NavbarSinSlider.vue";
import InstitucionalSkeleton from "../components/Skeletons/InstitucionalSkeleton.vue";
import Api from "../providers/api";
import Config from "../configs/configs";

export default {
  name: "Institucional",
  components: {
    NavbarSinSlider,
    InstitucionalSkeleton,
  },
  data() {
    return {
      onLoad: true,
      autoridades: [],
      sedes: [],
      urlBase: Config.API.IMG,
    };
  },
  methods: {
    getInformacionInstitucional() {
      const Institucional = Api("institucional");

      Institucional.get().then((response) => {
        this.autoridades = response.success ? response.autoridad : [];
        this.sedes = response.success ? response.sede : [];
        this.onLoad = false;
      });
    },
  },
  created: async function () {
    this.getInformacionInstitucional();
  },
};
</script>

<style scoped>
#image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-item {
  height: 7em;
  width: 7em;
  background-size: cover;
  background-position: center;
}

.icon-facebook {
  color: #3c599a;
}

.icon-twitter {
  color: #2ea1f2;
}

.icon-facebook:hover {
  color: black;
}

.icon-twitter:hover {
  color: black;
}

.autoridad-container {
  padding: 1em 6em 1em 6em;
}

h3 {
  color: var(--gris-4);
}

.shadow-container {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.2em !important;
  background-color: var(--gris-suave);
}

@media (min-width: 700px) and (max-width: 1080px) {
  .autoridad-container {
    padding: 1em 1em 1em 1em;
  }
}

@media (max-width: 890px) {
  .autoridad-container {
    padding: 1em 1em 1em 1em;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .image-item{
     margin-left: auto !important;
    margin-right: auto !important;
  }
  
  .redes-sociales{
    text-align: center !important;
  }
}

@media (max-width: 413px) {
}
</style>
