<template>
  <div class="row">
    <div class="col-md-6 col-lg-4 col-xl-3 img-container" v-for="actividad in actividades" :key="actividad.nombre">
      <div
        class="img-cuadrada"
        :style="`background-image: url('${actividad.img}')`"
      ></div>
      <div class="texto-donde-ir">
        <span class="img-title">{{actividad.nombre}}</span>
        <span class="border-text"></span>
        <span
          class="description w3-animate-bottom"
        >{{actividad.desc}}</span>
        <span class="text-link w3-animate-bottom">
          <router-link :to="{path: `actividades/${actividad.url}${$route.query.city ? `?city=${$route.query.city}` : ''}`}" :props="actividad.id" class="w3-animate-bottom">Ver actividades</router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Configs from "../configs/configs";

export default {
  name: "CuadriculaActividades",
  data(){
      return {
          actividades: [
              {
                nombre: 'Turismo de bienestar',
                img: Configs.BASE + 'images/cuadricula-actividades/12_400x400_TurismoDeBienestar.jpg',
                url: 'turismo-de-bienestar'
              },
              {
                nombre: 'Turismo en naturaleza',
                img: Configs.BASE + 'images/cuadricula-actividades/13_400x400_TurismoEnNaturaleza.JPG',
                url: 'turismo-en-naturaleza'
              },
              {
                nombre: 'Turismo enogas - tronómico',
                img: Configs.BASE + 'images/cuadricula-actividades/14_400x000_TurismoGastronomico.jpg',
                url: 'turismo-enogastronomico'
              },
              {
                nombre: 'Turismo de reuniones',
                img: Configs.BASE + 'images/cuadricula-actividades/15_400x400_TurismoDeReuniones.jpg',
                url: 'turismo-de-reuniones'
              },
              {
                nombre: 'Turismo rural',
                img: Configs.BASE + 'images/cuadricula-actividades/01_TurismoRural_400x400.jpg',
                url: 'turismo-rural'
              },
              {
                nombre: 'Turismo cultura-historia',
                img: Configs.BASE + 'images/cuadricula-actividades/16_400x400_TurismoCulturaHistoria.jpg',
                url: 'turismo-cultura-historia'
              },
              {
                nombre: 'Pesca deportiva',
                img: Configs.BASE + 'images/cuadricula-actividades/04_PescaConDevolucion_400x400.jpg',
                url: 'pesca-deportiva'
              },
              {
                nombre: 'Fiestas populares',
                img: Configs.BASE + 'images/cuadricula-actividades/17_400x400_FiestasPopulares.jpg',
                url: 'fiestas-populares'
              },
              {
                nombre: 'Playas',
                img: Configs.BASE + 'images/cuadricula-actividades/18_400x400_Playas.jpg',
                url: 'playas'
              },
              {
                nombre: 'Carnaval',
                img: Configs.BASE + 'images/cuadricula-actividades/19_400x400_Carnaval.jpg',
                url: 'carnaval'
              },
              {
                nombre: 'Turismo accesible',
                img: Configs.BASE + 'images/cuadricula-actividades/20_400x400_TurismoAccesible.png',
                url: 'turismo-accesible'
              },
              {
                nombre: 'Golf',
                img: Configs.BASE + 'images/cuadricula-actividades/21_400x400_Golf.jpg',
                url: 'golf'
              },
              {
                nombre: 'Eventos deportivos',
                img: Configs.BASE + 'images/cuadricula-actividades/22_400x400_EventosDeportivos.jpg',
                url: 'eventos-deportivos'
              }/*,
              {
                nombre: 'Turismo social',
                img: Configs.BASE + 'images/cuadricula-actividades/23_400x400_TurismoSocial.jpg',
                url: 'turismo-social'
              }*/
          ]
      }
  }
};
</script>

<style scoped>

.img-container{
    padding: .5em;
}

.img-cuadrada {
  width: 100%;
  height: 18em;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(80%);
}

.texto-donde-ir {
  bottom: 10%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}

.description{
    text-align: center;
}
.text-link{
    text-align: center;
}

.texto-donde-ir span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6em;
  line-height: 1.1em;
  padding-left: 0.1em;
  /*-webkit-text-stroke: 0.03em white;
  text-shadow:
    -1px -1px 1.3em #000000, 
    1px -1px 1.3em #000000,
    -1px 1px 1.3em #000000,
    1px 1px 1.3em #000000;*/
}

.texto-donde-ir .description {
  display: none;
  color: white;
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: 300;
  text-transform: inherit;
}

.text-link a {
  display: none;
  color: white;
  text-decoration: underline;
  font-size: 0.6em;
  font-weight: bold;
}

.img-title{
    text-align: center !important;
}

.img-container:hover > .texto-donde-ir {
  top: 25%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}

.img-container:hover > .texto-donde-ir .description {
  display: block;
}

.img-container:hover > .texto-donde-ir .text-link a {
  display: block;
}

.img-container:hover > .texto-donde-ir .img-title {
  position: relative;
  animation: animatebottom 0.4s;
}

.img-container:hover > .texto-donde-ir .border-text {
  position: relative;
  animation: animatebottom 0.4s;
}

.img-container:hover > .img-cuadrada {
  filter: brightness(30%);
}

.texto-donde-ir .border-text {
  border-bottom: 0.15em solid white;
  width: 3em;
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
}

.w3-animate-bottom {
  position: relative;
  animation: animatebottom 0.4s;
}
@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
</style>
