import Vue from 'vue';
import App from './App.vue';
import router from './router'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMapMarkerAlt, faPhoneSquareAlt, faGlobeAmericas, faEnvelope, faMobileAlt, faCalendarDay} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faMapMarkerAlt, faPhoneSquareAlt, faGlobeAmericas, faEnvelope, faMobileAlt, faCalendarDay)

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
