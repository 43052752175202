<template>
  <div class="row">
    <div class="col-md-6 col-xl-3 img-container">
      <div
        class="img-cuadrada mx-auto"
        :style="`background-image: url('${base_url}images/intro-home/02c_Promociones400pxX400px.jpg')`"
      ></div>
      <div class="texto-donde-ir">
        <span class="img-title">promociones</span>
        <span class="border-text"></span>
        <span class="description w3-animate-bottom"
          >Conoce las promociones de nuestra provincia.</span
        >
        <span class="text-link w3-animate-bottom">
          <router-link :to="{ path: '/promociones' }" class="w3-animate-bottom"
            >Más información</router-link
          >
        </span>
      </div>
    </div>
    <div class="col-md-6 col-xl-3 img-container">
      <div
        class="img-cuadrada mx-auto"
        :style="`background-image: url('${base_url}images/intro-home/01_400x400_DondeIr.jpg')`"
      ></div>
      <div class="texto-donde-ir">
        <span class="img-title">Donde ir</span>
        <span class="border-text"></span>
        <span class="description w3-animate-bottom"
          >Visita los destinos de nuestra provincia.</span
        >
        <span class="text-link w3-animate-bottom">
          <router-link :to="{ path: '/destinos' }" class="w3-animate-bottom"
            >Más información</router-link
          >
        </span>
      </div>
    </div>
    <div class="col-md-6 col-xl-3 img-container">
      <div
        class="img-cuadrada mx-auto"
        :style="`background-image: url('${base_url}images/intro-home/02_400x400_QueHacer.jpg')`"
      ></div>
      <div class="texto-donde-ir">
        <span class="img-title">Qué hacer</span>
        <span class="border-text"></span>
        <span class="description w3-animate-bottom"
          >Experiencias para disfrutar en nuestra provincia.</span
        >
        <span class="text-link w3-animate-bottom">
          <router-link :to="{ path: '/actividades' }" class="w3-animate-bottom"
            >Más información</router-link
          >
        </span>
      </div>
    </div>
    <div class="col-md-6 col-xl-3 img-container">
      <div
        class="img-cuadrada mx-auto"
        :style="`background-image: url('${base_url}images/intro-home/03ae_Noticias.png')`"
      ></div>
      <div class="texto-donde-ir">
        <span class="img-title">Noticias</span>
        <span class="border-text"></span>
        <span class="description w3-animate-bottom"
          >Novedades del Turismo en nuestra provincia.</span
        >
        <span class="text-link w3-animate-bottom">
          <router-link :to="{ path: '/noticias' }" class="w3-animate-bottom"
            >Más información</router-link
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Configs from "../configs/configs";
export default {
  name: "CuadriculaCuatroImagenes",
  data() {
    return {
      base_url: Configs.BASE,
    };
  },
};
</script>

<style scoped>
.img-cuadrada {
  width: 18em;
  height: 18em;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(80%);
}

.img-title {
  text-align: center !important;
}

.texto-donde-ir {
  top: 75%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  
}

.texto-donde-ir span {
  color: white;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.2em;
  padding-left: 0.1em;
  /*-webkit-text-stroke: 0.03em white;
  text-shadow:
    -1px -1px 1.3em #000000, 
    1px -1px 1.3em #000000,
    -1px 1px 1.3em #000000,
    1px 1px 1.3em #000000;*/
}

.texto-donde-ir .description {
  display: none;
  color: white;
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: 300;
  text-transform: inherit;
}

.text-link a {
  display: none;
  color: white;
  text-decoration: underline;
  font-size: 0.6em;
  font-weight: bold;
}
.img-container{
  display: flex;
}
.img-container:hover > .texto-donde-ir {
  top: 25%;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}

.img-container:hover > .texto-donde-ir .description {
  display: block;
}

.img-container:hover > .texto-donde-ir .text-link a {
  display: block;
}

.img-container:hover > .texto-donde-ir .img-title {
  position: relative;
  animation: animatebottom 0.4s;
}

.img-container:hover > .texto-donde-ir .border-text {
  position: relative;
  animation: animatebottom 0.4s;
}

.img-container:hover > .img-cuadrada {
  filter: brightness(20%);
}

.texto-donde-ir .border-text {
  border-bottom: 0.15em solid white;
  width: 3em;
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
}

.w3-animate-bottom {
  position: relative;
  animation: animatebottom 0.4s;
}
@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@media only screen and (max-width: 599px) {
  /* mobile */
  .img-container {
    margin-bottom: 1em;
  }

  .img-cuadrada {
    height: 15em;
    width: 15em;
  }
}

@media only screen and (min-width: 600px) {
  /* tablets */

  .img-container {
    margin-bottom: 1em;
  }
}

@media only screen and (min-width: 1024px) {
  /* desktop */
}
</style>
