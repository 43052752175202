<template>
  <div>
    <div id="first-background" class="pt-3"></div>
    <Navbar />
    <div id="second-background">
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";

export default {
  name: "NavbarSinSlider",
  components: {
    Navbar
  }
};
</script>

<style scoped>
#first-background {
  background-color: #001621;
  height: 6em;
}

#second-background {
  margin-top: -6em;
  background-color: #3080ad;
  height: 2em;
}

#slider-home {
  max-width: 90%;
  position: relative;
}

.carousel-item img {
  height: 22em;
}

.colors-border {
  border-bottom: 1em solid;
  border-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 5%,
      var(--azul-1) 5%,
      var(--azul-1) 20%,
      var(--azul-2) 20%,
      var(--azul-2) 35%,
      var(--azul-4) 35%,
      var(--azul-4) 50%,
      var(--verde-2) 50%,
      var(--verde-2) 65%,
      var(--verde-1) 65%,
      var(--verde-1) 80%,
      var(--naranja) 80%,
      var(--naranja) 95%,
      rgba(0, 0, 0, 0) 95%
    )
    5;
}

</style>
